import decamelize from "decamelize";

export function getPropValue(
  sourceObject: { [key: string]: any },
  dotNotationPath: string
) {
  let returnData = sourceObject;

  dotNotationPath.split(".").forEach((subPath) => {
    returnData = returnData?.[subPath] || undefined;
  });

  return returnData;
}

export const hasValue = (obj: { [key: string]: any }, value: any) =>
  Object.values(obj).includes(value);

export const isDefined = (value) => value !== undefined && value !== null;

export const transformKeysDeep = (obj, transform) => {
  if (Array.isArray(obj)) {
    return obj.map((value) => transformKeysDeep(value, transform));
  }
  if (obj !== null && typeof obj === "object") {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = transform(key);
      acc[newKey] = transformKeysDeep(value, transform);
      return acc;
    }, {});
  }
  return obj;
};

export const decamelizeKeysDeep = (obj) => transformKeysDeep(obj, (key) => decamelize(key));
