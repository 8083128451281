import { Eligibility, IncomeType, PinwheelProduct } from "types/signup";
import { ApiUserProfile } from "utils/api/user";
import { ApiDashboardOverview } from "utils/api/dashboard";
import { DISALLOWED_IDS } from "./constants";

export const isPayrollAllowed = (id) => !DISALLOWED_IDS.includes(id);

export const supports = (requiredProducts, products) =>
  requiredProducts.every((p) => products.includes(p)) ? "yes" : "no";

export const supportsPaystubsDds = ({ products }) =>
  supports([PinwheelProduct.paystubs, PinwheelProduct.dds], products);

export const supportsIncomeEmployment = ({ products }) =>
  supports([PinwheelProduct.income, PinwheelProduct.employment], products);

export const supportsShifts = ({ products }) =>
  supports([PinwheelProduct.shifts], products);

export const supportsPaystubsIncomeEmploymentDds = ({ products }) =>
  supports(
    [
      PinwheelProduct.paystubs,
      PinwheelProduct.employment,
      PinwheelProduct.income,
      PinwheelProduct.dds,
    ],
    products
  );

export const markEligible = () => Eligibility.eligible;
export const markIneligible = () => Eligibility.ineligible;

export const CONDITIONS = {
  root: ({ getValues }) => getValues("questionnaire[incomeType]"), // hourly, gig, salary, benefits

  // What type of income?
  "root.gig": ({ products }) => (products ? "yes" : "no"),
  "root.salary": ({ products }) => (products ? "yes" : "no"),
  "root.benefits": ({ products }) => (products ? "yes" : "no"),
  "root.hourly": ({ products }) => (products ? "yes" : "no"),

  // Gig
  // Has user selected a provider?
  "root.gig.no": () => Eligibility.indeterminate,
  "root.gig.yes": supportsPaystubsDds,
  // Does it support paystubs or DDS?
  "root.gig.yes.no": markIneligible,
  "root.gig.yes.yes": supportsShifts,
  // Does it support shifts?
  "root.gig.yes.yes.no": markIneligible,
  "root.gig.yes.yes.yes": markEligible,

  // Salary
  // Has user selected a provider?
  "root.salary.no": () => Eligibility.indeterminate,
  "root.salary.yes": supportsPaystubsDds,
  // Does it support paystubs or DDS?
  "root.salary.yes.no": markIneligible,
  "root.salary.yes.yes": supportsIncomeEmployment,
  // Does it support income and employment?
  "root.salary.yes.yes.no": markIneligible,
  "root.salary.yes.yes.yes": markEligible,

  // Benefits
  // Has user selected a provider?
  "root.benefits.no": () => Eligibility.indeterminate,
  "root.benefits.yes": supportsPaystubsDds,
  // Does it support paystubs or DDS?
  "root.benefits.yes.no": markIneligible,
  "root.benefits.yes.yes": supportsIncomeEmployment,
  // Does it support income and employment?
  "root.benefits.yes.yes.no": markIneligible,
  "root.benefits.yes.yes.yes": markEligible,

  // Hourly
  // Has user selected a provider?
  "root.hourly.no": () => Eligibility.indeterminate,
  "root.hourly.yes": ({ getValues }) =>
    getValues("questionnaire[tracksHoursWithPayroll]"),
  // Do you track your hours in your payroll provider?
  "root.hourly.yes.no": supportsPaystubsIncomeEmploymentDds,
  "root.hourly.yes.yes": supportsPaystubsIncomeEmploymentDds,
  // Does it support paystubs, income, employment and DDS?
  // We no longer care if the hourly user's payroll provider supports shifts or if they log shifts in a different
  //  system since we can override their income
  "root.hourly.yes.yes.no": markIneligible,
  "root.hourly.yes.yes.yes": markEligible,
  "root.hourly.yes.no.no": markIneligible,
  "root.hourly.yes.no.yes": markEligible,
};

export const pathIncludes = (path: string[], subPath: string | string[]) => {
  const currentPath = path.join(".");
  const pathsToMatch = Array.isArray(subPath) ? subPath : [subPath];
  return pathsToMatch.some((p) => currentPath.indexOf(p) === 0);
};

export const getIsEligible = (eligibility) =>
  Object.values(eligibility).every((v) => v === Eligibility.eligible);

export const getWaitlistBody = ({
  userProfile,
  dashboardOverview,
  values,
}: {
  userProfile: ApiUserProfile;
  dashboardOverview: ApiDashboardOverview;
  values: any;
}) => {
  const user = {
    email: userProfile.email,
    name: `${userProfile.firstName} ${userProfile.lastName}`,
  };
  const payroll = {
    payrollProviderName:
      values.payroll.id === "other"
        ? values.payroll.otherName
        : values.payroll.name,
    pinwheelId: values.payroll.id,
    incomeType: values.questionnaire?.incomeType,
  };
  const timeAndAttendance =
    values.questionnaire?.incomeType === IncomeType.hourly &&
    values.timeAndAttendance?.id
      ? {
          timeAndAttendancePlatformPinwheelId: values.timeAndAttendance.id,
          timeAndAttendancePlatformName:
            values.timeAndAttendance.id === "other"
              ? values.timeAndAttendance.otherName
              : values.timeAndAttendance.name,
        }
      : {};
  const bankAccountName = dashboardOverview.accounts.primaryBank;

  return {
    ...user,
    ...payroll,
    ...timeAndAttendance,
    bankAccountName,
    userId: userProfile.id,
  };
};
