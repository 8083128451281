import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  gap: 48px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
