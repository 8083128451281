import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { usePlaidLink } from "react-plaid-link";
import * as apiPlaid from "utils/api/plaid";

interface Props {
  onExit?: () => void;
  onSuccess?: (public_token: string, callbackData?: any) => void;
  onSubmit?: (public_token: string, callbackData?: any) => void;
}

export interface ModalApi {
  open: (callbackData?: any) => void;
}

const PlaidModal = (
  { onSuccess, onExit, onSubmit }: Props,
  ref: React.ForwardedRef<unknown>
) => {
  const [linkToken, setLinkToken] = useState<string | undefined>();
  const [callbackData, setCallbackData] = useState<any | undefined>();

  const cleanup = () => {
    setCallbackData(undefined);
  };

  const { open: openPlaidLink, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token) => {
      if (onSubmit) {
        onSubmit(public_token, callbackData);
      }
      await apiPlaid.exchangePublicTokenToAccessToken(public_token);
      if (onSuccess) {
        onSuccess(public_token, callbackData);
      }
      cleanup();
    },
    onExit: () => {
      if (onExit) {
        onExit();
      }
      cleanup();
    },
  });

  const open = async (data) => {
    setCallbackData(data);

    const res = await apiPlaid.getLinkToken();

    if (res.success && res.link_token) {
      setLinkToken(res.link_token);
    }
  };

  useEffect(() => {
    if (ready) {
      openPlaidLink();
    }
  }, [ready, openPlaidLink, linkToken]);

  useImperativeHandle(ref, () => ({ open }));

  return <></>;
};

export default forwardRef(PlaidModal);
