import React, { useEffect, useState } from "react";
import {
  getDashboardOverview,
  getNotifications,
  usePostNotificationMarkAsReadMutation,
} from "store/api/apiSlice";
import { POLLING_INTERVAL } from "utils/api/common";
import { ApiNotification, ApiNotificationType } from "utils/api/notifications";
import {
  hasDslUpdatedNotification,
  hasDslUpdatingNotification,
} from "utils/notifications";

interface Props {
  children: (props: {
    notifications: ApiNotification[];
    onResolveIntegrationError: () => void;
  }) => React.ReactElement;
}

const NotificationsContainer: React.FC<Props> = ({ children }) => {
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);
  const { data: notifications = [], refetch: refetchNotifications } =
    getNotifications({
      pollingInterval,
    });
  const { refetch: refetchDashboard } = getDashboardOverview();
  const [markAsRead] = usePostNotificationMarkAsReadMutation();

  const isUpdatingDsl = hasDslUpdatingNotification(notifications);
  const isUpdatedDsl = hasDslUpdatedNotification(notifications);

  const onResolveIntegrationError = () => {
    refetchNotifications();
    setPollingInterval(1_000);
  };

  useEffect(() => {
    setPollingInterval(isUpdatingDsl ? 1_000 : POLLING_INTERVAL);
  }, [isUpdatingDsl]);

  useEffect(() => {
    if (isUpdatedDsl) {
      const updatedNotification = notifications.find(
        (notification) => notification.type === ApiNotificationType.dslUpdated
      );
      if (updatedNotification) {
        markAsRead(updatedNotification.id);
      }
      refetchDashboard();
    }
  }, [isUpdatedDsl]);

  return children({ notifications, onResolveIntegrationError });
};

export default NotificationsContainer;
