import React from "react";
import UILayout from "components/layout/UILayout";
import Loading from "components/ui/Loading";
import { Heading } from "components/ui/Text";
import BankAccount from "components/shared/BankAccount";
import { getBankAccountsFbo } from "store/api/apiSlice";
import { FormSection } from "components/form";
import { Wrapper } from "../styled";

const FboAccount = () => {
  const { data: fboAccount, isLoading } = getBankAccountsFbo();

  if (isLoading) return <Loading />;

  if (!fboAccount) return null;

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">Set up direct deposit</Heading.H3>
        <FormSection>
          <BankAccount bankAccount={fboAccount} />
        </FormSection>
      </Wrapper>
    </UILayout>
  );
};

export default FboAccount;
