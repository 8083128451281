import React from "react";
import Icon, { IconName } from "components/ui/Icon";
import Logo from "components/ui/Logo";
import { colors } from "utils/theme";
import * as Styled from "./styled";
import { ButtonSize } from "../Button";

const NAVIGATION: Array<{
  title: string;
  shortTitle?: string;
  icon: IconName;
  path: string;
}> = [
  {
    title: "Home",
    icon: "house",
    path: "",
  },
  {
    title: "Users",
    icon: "userCircle",
    path: "users",
  },
  {
    title: "Repayment Schedule",
    icon: "calendar",
    path: "repayment-schedule",
  },
  {
    title: "Card Transactions",
    shortTitle: "Card Txns",
    icon: "creditCard",
    path: "card-transactions",
  },
  {
    title: "Waitlist",
    icon: "squareList",
    path: "waitlists",
  },
  {
    title: "Financial Health Survey",
    shortTitle: "FH Survey",
    icon: "stethoscope",
    path: "financial-health",
  },
];

const getPath = (path: string) =>
  `/${["admin", path].filter((str) => str).join("/")}`;

const AdminNavigation: React.FC = () => (
  <Styled.Wrapper>
    <Styled.NavInner>
      <Styled.LogoWrapper>
        <Logo />
      </Styled.LogoWrapper>

      <Styled.NavItems>
        {NAVIGATION.map((item) => (
          <Styled.NavItem key={item.title}>
            <Styled.NavLink to={getPath(item.path)} end>
              <Icon name={item.icon} color={colors.white} />
              <Styled.Label>{item.title}</Styled.Label>
              <Styled.ShortLabel>
                {item.shortTitle || item.title}
              </Styled.ShortLabel>
            </Styled.NavLink>
          </Styled.NavItem>
        ))}
      </Styled.NavItems>

      <Styled.AdminLink>
        <Styled.AdminButtonLink href="/dashboard" size={ButtonSize.md}>
          Dashboard
        </Styled.AdminButtonLink>
      </Styled.AdminLink>
    </Styled.NavInner>
  </Styled.Wrapper>
);

export default AdminNavigation;
