import React, { useState } from "react";
import Loading from "components/ui/Loading";
import { OnboardingTaskName } from "types/onboarding";
import { getOnboardingStatus } from "store/api/apiSlice";
import Payroll from "./Payroll";
import Bank from "./Bank";
import IneligibleMessage from "./IneligibleMessage";

const Step: React.FC<{ id: OnboardingTaskName }> = ({ id }) => {
  const [isPolling, setIsPolling] = useState(true);
  const { data: onboardingStatus } = getOnboardingStatus({
    pollingInterval: isPolling ? 2000 : undefined,
  });

  if (!onboardingStatus) return <Loading />;

  if (onboardingStatus.ineligible) return <IneligibleMessage />;

  switch (id) {
    case OnboardingTaskName.payrollLinking:
      return (
        <Payroll
          onboardingStatus={onboardingStatus}
          setIsPolling={setIsPolling}
        />
      );
    case OnboardingTaskName.bankLinking:
      return (
        <Bank onboardingStatus={onboardingStatus} setIsPolling={setIsPolling} />
      );
    default:
      return null;
  }
};

export default Step;
