import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import FormContainer, { RadioField, SubmitButton } from "components/form";
import UILabel from "components/ui/UILabel";
import { usePostAdminUserDebitExternalAccountMutation } from "store/admin/api/apiSlice";
import { Heading } from "components/ui/Text";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface ApiError {
  data: { error: String };
}

interface Props {
  id: string;
  onSuccess: () => void;
}

const DebitExternalAccountModal = (
  { id, onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postDebitExternalAccount, postDebitExternalAccountContext] =
    usePostAdminUserDebitExternalAccountMutation();
  const today = new Date();
  const tomorrow = new Date();
  // setDate smartly handles end of month
  tomorrow.setDate(today.getDate() + 1);

  const onSubmit = (values: { effectiveDate: string }) => {
    const debitParams: {
      id: string;
      effective_date: string;
    } = {
      id,
      effective_date: values.effectiveDate,
    };

    debitParams.effective_date = new Date(values.effectiveDate)
      .toISOString()
      .replace(/T.+/, "");

    postDebitExternalAccount(debitParams);
  };

  useEffect(() => {
    if (postDebitExternalAccountContext.isSuccess && onSuccess) {
      onSuccess();
    }

    ref.current?.dismiss();
  }, [postDebitExternalAccountContext.isSuccess]);

  const errorMessage = postDebitExternalAccountContext.isError ? (
    <UILabel.Large color="cerise">
      {(postDebitExternalAccountContext.error as ApiError).data.error}
    </UILabel.Large>
  ) : null;

  return (
    <Modal ref={ref}>
      <Wrapper>
        <Heading.H4 tag="h2">Debit External Account</Heading.H4>

        <FormContainer onSubmit={onSubmit}>
          <UILabel.Large>
            This will create an ACH Debit for the current outstanding balance.
          </UILabel.Large>
          <UILabel.Large>
            ACH Debits can only be triggered for Today or Tomorrow. If created
            after <b>1:25 PM PT / 4:25 PM ET</b>, they will be processed the
            next business day as Same Day ACH, regardless of whether you select
            Today or Tomorrow.
          </UILabel.Large>

          {postDebitExternalAccountContext.isError && errorMessage}

          <RadioField
            name="effectiveDate"
            options={[
              {
                label: "Today",
                value: today.toISOString().replace(/T.+/, ""),
              },
              {
                label: "Tomorrow",
                value: tomorrow.toISOString().replace(/T.+/, ""),
              },
            ]}
          />

          <SubmitButton>Debit External Account</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(DebitExternalAccountModal);
