import React from "react";
import styled, { css } from "styled-components";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import { AlertLevel } from "types/alerts";
import { colors, mediaMax, shadows } from "utils/theme";

export { AlertLevel };

const getColor = (level: AlertLevel) => {
  switch (level) {
    case AlertLevel.success:
    case AlertLevel.info:
      return colors.azure;
    case AlertLevel.warn:
      return colors.cosmos;
    case AlertLevel.error:
    default:
      return colors.cerise;
  }
};

const Wrapper = styled.a<{
  $level?: AlertLevel;
}>`
  position: relative;

  display: flex;
  padding: 16px 16px 16px 24px;
  width: 100%;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  min-height: 72px;

  border-radius: 12px;
  background: white;
  box-shadow: ${shadows.tile};
  color: ${colors.midnight};

  text-decoration: none;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  &[href] {
    cursor: pointer;
  }

  &::before {
    content: "";

    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;

    width: 4px;

    background: ${(props) => getColor(props.$level)};
    border-radius: 2px;
  }

  ${mediaMax("md")} {
    padding: 12px 16px 12px 24px;
  }
`;

const ArrowIcon = styled.div`
  svg {
    display: block;
  }
`;

const Message = styled.div`
  flex: 1 1 auto;
`;

interface Props {
  level?: AlertLevel;
  children: React.ReactNode;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Alert: React.FC<Props> = ({
  title,
  children,
  level = AlertLevel.info,
  onClick,
}) => (
  <Wrapper onClick={onClick} $level={level}>
    <Message>
      {title && (
        <UILabel.Small caps bold>
          {title}
        </UILabel.Small>
      )}
      <UILabel.Large>{children}</UILabel.Large>
    </Message>

    {onClick && (
      <ArrowIcon>
        <Icon name="caretRightSolid" size="sm" color={colors.violet} />
      </ArrowIcon>
    )}
  </Wrapper>
);

export default Alert;
