import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePostUserMutation } from "store/api/apiSlice";
import { toHash } from "utils/string";
import { EventType, trackEvent } from "utils/analytics";
import Fields from "./Fields";
import Intro from "./Intro";

interface Props {}

const Form: React.FC<Props> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_postSignup, postSignupContext] = usePostUserMutation({
    fixedCacheKey: "signup",
  });
  const [showIntro, setShowIntro] = useState(true);

  const form = useFormContext();

  const onSuccess = async (resetPasswordToken) => {
    const userId = await toHash(form.getValues("user.email"));
    trackEvent(EventType.userSignupSubmitted, {
      user_id: userId,
      eventCallback: () => {
        window.location.href = `/users/password/edit?reset_password_token=${resetPasswordToken}&signup=true`;
      },
    });
  };

  useEffect(() => {
    if (postSignupContext.data?.resetPasswordToken) {
      onSuccess(postSignupContext.data.resetPasswordToken);
    }
  }, [postSignupContext.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showIntro]);

  if (showIntro) return <Intro hideIntro={() => setShowIntro(false)} />;

  return <Fields />;
};

export default Form;
