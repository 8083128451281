import { removeAllCookies } from "utils/cookie";
import environment from "utils/environment";
import { PartnerSlug } from "types/general";
import { Address, AddressRequest } from "./address";
import { ApiCardAccount } from "./cardAccount";
import { getHeaders } from "./common";

export interface ApiUserRequest {
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
  referralSlug?: string;
}

export interface ApiQualifierRequest {
  name: string;
  email: string;
  userId?: string;
  bankAccountName?: string;
  plaidId?: string;
  payrollProviderName: string;
  pinwheelId: string;
  incomeType: string;
  timeAndAttendancePlatformName?: string;
  timeAndAttendancePlatformPinwheelId?: string;
}

export enum RepaymentMethod {
  directDepositFlow = "direct_deposit_flow",
  achDebitFlow = "ach_debit_flow",
}

export interface ApiUserProfile {
  id: string;
  firstName: string | null;
  lastName: string | null;
  createdAt: string;
  email: string;
  phoneNumber?: string;
  currentAddress?: Address;
  currentCardAccount?: ApiCardAccount;
  mfaEnabled: boolean;
  smsOptIn: boolean;
  confirmed: boolean;
  admin?: boolean;
  impersonating?: boolean;
  eligibleForCard: boolean;
  hasFboAccount: boolean;
  enableProbationaryLimit: boolean;
  repaymentMethod: RepaymentMethod;
  partnerSlug?: PartnerSlug;
  partnerLogo?: {
    // currently only for mock API customizations
    data: string;
    type: string;
  };
}

export interface ApiPersonalInfosRequest {
  phoneNumber: string;
  dateOfBirth: string;
  ssn: string;
  address: AddressRequest;
}

export const logout = async () => {
  const res = await fetch("/users/sign_out", {
    credentials: "include",
  });
  if ([200, 204].includes(res.status)) {
    removeAllCookies();

    if (!environment.enableDemoTriggers) {
      localStorage.clear();
      sessionStorage.clear();
    }

    window.location.pathname = "/";
  }
};

export const editPassword = async (body) =>
  fetch("/api/v1/users/passwords", {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(body),
  });
