// interface

export enum ApiNotificationErrorType {
  monitoring_status_unavailable = "monitoring_status_unavailable",
  direct_deposit_switched_away_from_reset = "direct_deposit_switched_away_from_reset",
}

export enum ApiNotificationSource {
  pinwheel = "pinwheel",
  plaid = "plaid",
}

export enum ApiNotificationType {
  integrationError = "IntegrationErrorNotifier::Notification",
  dslUpdating = "DynamicSpendingLimits::UpdatingNotifier::Notification",
  dslUpdated = "DynamicSpendingLimits::UpdatedNotifier::Notification",
  optedToSkipPayrollLinking = "PayrollAccounts::PayrollLinkingSkippedNotifier::Notification",
}

export interface ApiNotification {
  id: string;
  type: ApiNotificationType;
  actedAt: string | null;
  readAt: string | null;
  requireAction: boolean;
  params: {
    errorType?: ApiNotificationErrorType;
    source?: ApiNotificationSource;
  };
}
