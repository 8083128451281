import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Dashboard from "components/views/Dashboard";
import CardTransactions from "components/views/CardTransactions";
import Income from "components/views/Income";
import Statements from "components/views/Account/Statements/Root";
import UpcomingBills from "components/views/UpcomingBills";
import Activate from "components/views/Activate";
import Account from "components/views/Account/Root";
import Documents from "components/views/Account/Documents";
import ManageAccount from "components/views/Account/ManageAccount";
import PersonalInfo from "components/views/Account/PersonalInfo";
import Security from "components/views/Account/Security";
import ReplaceCard from "components/views/Account/ReplaceCard";
import ContentfulPage from "components/shared/ContentfulPage";
import Statement from "components/views/Account/Statements/Statement";
import TextMessageSettings from "components/views/Account/TextMessageSettings";
import FboAccount from "components/views/Account/FboAccount";
import Root from "./Root";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
        handle: { title: "Dashboard" },
      },
      {
        path: "/card-transactions",
        element: <CardTransactions />,
        handle: { title: "Card Transactions" },
      },
      { path: "/income", element: <Income />, handle: { title: "Income" } },
      {
        path: "/account",
        children: [
          { path: "", element: <Account />, handle: { title: "Account" } },
          {
            path: "personal-info",
            element: <PersonalInfo />,
            handle: { title: "Personal Info" },
          },
          {
            path: "security",
            element: <Security />,
            handle: { title: "Security" },
          },
          {
            path: "text-message-settings",
            element: <TextMessageSettings />,
            handle: { title: "Text Message Settings" },
          },
          {
            path: "fbo-account",
            element: <FboAccount />,
            handle: { title: "Set up Direct Deposit" },
          },
          {
            path: "replace-card",
            element: <ReplaceCard />,
            handle: { title: "Replace Card" },
          },
          {
            path: "documents",
            element: <Documents />,
            handle: { title: "Documents" },
          },
          {
            path: "manage-account",
            element: <ManageAccount />,
            handle: { title: "Manage Account" },
          },
          {
            path: "statements",
            children: [
              {
                path: "",
                element: <Statements />,
              },
              {
                path: ":id",
                element: <Statement />,
              },
            ],
            handle: { title: "Statements" },
          },
          { path: ":slug", element: <ContentfulPage /> },
        ],
      },
      {
        path: "/recurring-expenses",
        element: <UpcomingBills />,
        handle: {
          parent: "dashboard",
          title: "Recurring Expenses",
        },
      },
      {
        path: "/activate",
        element: <Activate />,
        handle: { title: "Activate Card" },
      },
    ],
  },
]);

export default router;
