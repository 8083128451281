import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Body, Heading } from "components/ui/Text";
import Tile from "components/ui/Tile";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import {
  getUserProfile,
  usePostSendConfirmationInstructionsForCurrentUserMutation,
} from "store/api/apiSlice";
import Icon from "components/ui/Icon";

const Wrapper = styled(Tile)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const UnconfirmedMessage = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [postResendConfirmation, postResendConfirmationContext] =
    usePostSendConfirmationInstructionsForCurrentUserMutation();
  const { data: userProfile } = getUserProfile({
    pollingInterval: isSuccess ? 2_000 : undefined,
  });

  const resendConfirmation = async () => {
    await postResendConfirmation();
  };

  useEffect(() => {
    if (postResendConfirmationContext.isSuccess) {
      setIsSuccess(true);
    }
  }, [postResendConfirmationContext.isSuccess]);

  return (
    <Wrapper size="xl">
      <Header>
        <Heading.H2 tag="h1">
          Please confirm your&nbsp;email address.
        </Heading.H2>
      </Header>

      <Main>
        <Body>
          We emailed a link to <strong>{userProfile?.email}</strong>. You must
          click it to verify your email address before proceeding. If you don't
          see the email in your inbox, please check your spam folder.
        </Body>
      </Main>

      <MobileFixedFooter>
        <Button
          onClick={resendConfirmation}
          size={ButtonSize.lg}
          level={ButtonLevel.cta}
          isLoading={postResendConfirmationContext.isLoading}
          disabled={isSuccess}
        >
          {isSuccess ? (
            <>
              <Icon name="checkmark" />
              Email Sent
            </>
          ) : (
            "Resend Confirmation Email"
          )}
        </Button>
      </MobileFixedFooter>
    </Wrapper>
  );
};

export default UnconfirmedMessage;
