import React from "react";
import PasswordFields from "components/shared/PasswordFields";
import OnboardingFlow from "components/shared/OnboardingFlow";
import SiteLayout from "components/layout/SiteLayout";
import CenteredLayout from "components/layout/CenteredLayout";
import { PartnerSlug } from "types/general";

const EditPassword = (props) => {
  const params = new URLSearchParams(window.location.search);
  const isSignup = params.get("signup");

  if (!isSignup)
    return (
      <SiteLayout>
        <CenteredLayout>
          <PasswordFields {...props} />
        </CenteredLayout>
      </SiteLayout>
    );

  const partnerSlug = sessionStorage.getItem("partner") as PartnerSlug;

  return (
    <OnboardingFlow partnerSlug={partnerSlug}>
      <PasswordFields {...props} isSignup />
    </OnboardingFlow>
  );
};

export default EditPassword;
