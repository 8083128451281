import React from "react";
import OnboardingFlow from "components/shared/OnboardingFlow";
import AppFooter from "components/shared/AppFooter";
import EligibilityFooter from "components/shared/EligibilityFooter";
import { logout } from "utils/api/user";
import { HelpMessage } from "components/form";
import { FormTaskStatus } from "types/task";
import { OnboardingTask, OnboardingTaskName } from "types/onboarding";
import {
  getUserProfile,
  usePostAdminUserStopImpersonatingMutation,
} from "store/api/apiSlice";
import Step from "./Step";
import { getSidebarNavigation } from "./utils";
import * as Styled from "./styled";

interface Props {
  tasks: OnboardingTask[];
}

const Flow: React.FC<Props> = ({ tasks }) => {
  const { data: userProfile } = getUserProfile();
  const [postStopImpersonatingUser] =
    usePostAdminUserStopImpersonatingMutation();
  const stopImpersonatingUser = async () => {
    await postStopImpersonatingUser();
    window.location.replace(`/admin/users/${userProfile.id}`);
  };

  const currentTaskIndex = tasks.findIndex(
    (step) => step.state === FormTaskStatus.ready
  );
  const currentTask = tasks[currentTaskIndex];

  const partnerSlug = userProfile?.partnerSlug;

  const sidebarNavigation = getSidebarNavigation({
    isImpersonating: userProfile?.impersonating,
    stopImpersonatingUser,
    logout,
  });

  return (
    <OnboardingFlow
      tasks={tasks}
      partnerSlug={partnerSlug}
      sidebarNavigation={sidebarNavigation}
      mobileNavigation={[
        {
          text: "Log Out",
          onClick: logout,
        },
      ]}
    >
      {!userProfile?.confirmed && (
        <Styled.AlertWrapper>
          <HelpMessage>
            We sent an email to <strong>{userProfile?.email}</strong>. Please
            check your inbox and click the link to confirm your email address.
          </HelpMessage>
        </Styled.AlertWrapper>
      )}

      <Step id={currentTask.name as OnboardingTaskName} />

      <Styled.ContentFooter>
        <EligibilityFooter />

        <AppFooter />
      </Styled.ContentFooter>
    </OnboardingFlow>
  );
};

export default Flow;
