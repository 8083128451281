import React, { useEffect, useRef, useState } from "react";
import { Heading } from "components/ui/Text";
import Button, { ButtonLevel } from "components/ui/Button";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import IconList, { IconListItem } from "components/ui/IconList";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { OnboardingTaskName } from "types/onboarding";
import { OnboardingStatus } from "utils/api/onboarding";
import * as Styled from "./styled";

const LIST_ITEMS: IconListItem[] = [
  {
    icon: "arrowProgress",
    title: "Why connect my checking account?",
    body: "Reset factors in your checking account balance and upcoming bills to create a dynamic spending limit tailored to you.",
  },
  {
    icon: "ballotCheck",
    title: "What if I have multiple accounts?",
    body: (
      <>
        Please choose your{" "}
        <em>
          <strong>primary</strong>
        </em>{" "}
        checking account, where the majority of your direct deposit goes.
      </>
    ),
  },
  {
    icon: "shield",
    title: "Is it safe?",
    body: "Reset uses Plaid to securely connect your checking account and keep your data private.",
  },
];

interface Props {
  onboardingStatus: OnboardingStatus;
  setIsPolling: (isPolling: boolean) => void;
}

const Bank: React.FC<Props> = ({ onboardingStatus, setIsPolling }) => {
  const modalRef = useRef<PlaidModalApi>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const openModal = () => {
    if (modalRef && modalRef.current) {
      modalRef.current.open();
      setIsPolling(false);
    }
  };

  const onSubmitPlaid = () => {
    setIsSuccess(true);
    setIsPolling(true);
  };

  useEffect(() => {
    if (
      onboardingStatus &&
      onboardingStatus.currentTask !== OnboardingTaskName.bankLinking
    ) {
      window.location.reload();
    }
  }, [onboardingStatus]);

  return (
    <Styled.FormStep>
      <Styled.Header>
        <Heading.H3 tag="h1">Let's connect your checking account.</Heading.H3>
      </Styled.Header>

      <IconList items={LIST_ITEMS} isLarge />

      <MobileFixedFooter>
        <Button
          isLoading={isSuccess}
          onClick={openModal}
          level={ButtonLevel.cta}
        >
          Connect My Checking Account
        </Button>
      </MobileFixedFooter>

      <PlaidModal ref={modalRef} onSubmit={onSubmitPlaid} />
    </Styled.FormStep>
  );
};

export default Bank;
