import React, { useEffect, useState } from "react";
import { BodySmall, Heading } from "components/ui/Text";
import Button from "components/ui/Button";
import FormContainer, { CheckboxField, FormSection } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import Loading from "components/ui/Loading";
import {
  getDashboardOverview,
  getDisclosures,
  usePostAcceptancesMutation,
} from "store/api/apiSlice";
import { isDefined } from "utils/object";
import { ApiDisclosure } from "utils/api/disclosures";
import * as Styled from "../styled";
import Label from "../Label";
import { getFields } from "./fields";
import { InfoType } from "../types";
import DslExplanation from "../DslExplanation";

const getCheckedDisclosureIds = (
  values: { [key: string]: string | boolean },
  disclosures: ApiDisclosure[]
) =>
  Object.values(values)
    .filter((checked) => checked)
    .reduce((acceptances: string[], checkboxAcceptances: string) => {
      const acceptedDisclosureTypes = checkboxAcceptances.split(",");
      const acceptedDisclosureIds = acceptedDisclosureTypes.map(
        (acceptedDisclosureType) => {
          const disclosure = disclosures.find(
            (d) => d.disclosureType === acceptedDisclosureType
          );

          return disclosure.id;
        }
      );

      return [...acceptances, ...acceptedDisclosureIds];
    }, []);

interface Props {
  showInfo: (infoType: InfoType) => () => void;
}

const TermsAcceptance: React.FC<Props> = ({ showInfo }) => {
  const params = new URLSearchParams(window.location.search);
  const skipIntro = JSON.parse(params.get("skip_intro")) || false;
  const [showDslExplanation, setShowDslExplanation] = useState(!skipIntro);
  const [isLoading, setIsLoading] = useState(false);
  const { data: disclosuresData, isLoading: isLoadingDisclosures } =
    getDisclosures();
  const disclosureFields = getFields(disclosuresData);
  const [postAcceptances, { data: acceptanceResponseData }] =
    usePostAcceptancesMutation();
  const { data: dashboard, isLoading: isDashboardLoading } =
    getDashboardOverview();

  const onSubmit = async (values) => {
    if (!disclosuresData?.disclosures) return;

    setIsLoading(true);

    const checkedDisclosures = getCheckedDisclosureIds(
      values,
      disclosuresData.disclosures
    );

    await postAcceptances({
      disclosures: checkedDisclosures,
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (acceptanceResponseData) {
      window.location.reload();
    }
  }, [acceptanceResponseData]);

  const { safeToSpend, maxSafeToSpend } =
    dashboard?.safeToSpendComponents || {};

  useEffect(() => {
    if (isDefined(maxSafeToSpend) && maxSafeToSpend === 0) {
      setShowDslExplanation(false);
    }
  }, [maxSafeToSpend]);

  if (isDashboardLoading) return <Loading />;

  if (showDslExplanation)
    return (
      <DslExplanation
        setShowDslExplanation={setShowDslExplanation}
        safeToSpend={safeToSpend}
        maxSafeToSpend={maxSafeToSpend}
      />
    );

  return (
    <FormContainer onSubmit={onSubmit}>
      <Styled.Header>
        <Heading.H2 tag="h1">
          Please accept the following terms to continue.
        </Heading.H2>
      </Styled.Header>

      <Styled.Alert onClick={showInfo(InfoType.patriotAct)} type="button">
        <BodySmall>
          IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT{" "}
          <u>Read more</u>
        </BodySmall>
      </Styled.Alert>

      {!isLoadingDisclosures && (
        <FormSection>
          {disclosureFields.map((item) => (
            <CheckboxField
              key={item.name}
              name={item.name}
              value={item.value}
              label={<Label nodes={item.label} />}
              required
            />
          ))}
        </FormSection>
      )}

      <MobileFixedFooter>
        <Button
          isLoading={isLoading}
          disabled={isLoadingDisclosures}
          type="submit"
        >
          Agree & Continue
        </Button>
      </MobileFixedFooter>
    </FormContainer>
  );
};

export default TermsAcceptance;
