import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import FormContainer, {
  CheckboxField,
  InputField,
  SubmitButton,
} from "components/form";
import { usePostAdminUserOverrideUserConfigMutation } from "store/admin/api/apiSlice";
import UILabel from "components/ui/UILabel";
import { Heading } from "components/ui/Text";
import { ApiAdminUser } from "utils/api/admin/user";
import Button from "components/ui/Button";
import { Pre } from "components/ui/Pre";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

const StyledHr = styled.hr`
  width: 100%;
  margin: 0px;
`;

interface Props {
  user: ApiAdminUser;
  onSuccess: () => void;
}

const OverrideUserConfigModal = (
  { user, onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postOverrideUserConfig, postOverrideUserConfigContext] =
    usePostAdminUserOverrideUserConfigMutation();

  const onSubmit = (values: {
    earnedWageAccessMultiplier?: number;
    requirePayrollLinking?: boolean;
    enableProbationaryLimit?: boolean;
  }) => {
    const overrideParams = {
      id: user.id,
      ...values,
    };

    postOverrideUserConfig(overrideParams);
  };

  const onRestoreDefaults = () => {
    postOverrideUserConfig({ id: user.id });
  };

  useEffect(() => {
    if (postOverrideUserConfigContext.isSuccess && onSuccess) {
      onSuccess();
    }

    ref.current?.dismiss();
  }, [postOverrideUserConfigContext.isSuccess]);

  // We can add more forms here for other user config overrides in the future

  return (
    <Modal ref={ref}>
      <Wrapper>
        <Heading.H4 tag="h2">Override User Configs</Heading.H4>

        <Pre json={user.userConfig} />
        <StyledHr />

        <FormContainer onSubmit={onSubmit}>
          <CheckboxField
            name="requirePayrollLinking"
            label="Require Payroll Linking"
            defaultChecked={user.userConfig.requirePayrollLinking}
          />

          <CheckboxField
            name="enableProbationaryLimit"
            label="Enable Probationary Limit"
            defaultChecked={user.userConfig.enableProbationaryLimit}
          />

          <InputField
            name="earnedWageAccessMultiplier"
            label="Earned Wage Access Multiplier"
            defaultValue={user.userConfig.earnedWageAccessMultiplier}
            placeholder="0.5"
            type="number"
            step="0.01"
          />
          <UILabel.Large>
            Earned Wage Access Multiplier must be a percent between 0 and 1.
          </UILabel.Large>

          <SubmitButton>Override User Config</SubmitButton>
        </FormContainer>

        <Button onClick={onRestoreDefaults}>Restore Defaults</Button>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(OverrideUserConfigModal);
