import DisappearingTile from "components/ui/DisappearingTile";
import styled from "styled-components";
import { colors, mediaMax, mediaMin } from "utils/theme";
import { ICON_WRAPPER_WIDTH } from "./constants";

export const LineItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  ${mediaMax("md")} {
    gap: 16px;
  }
`;

export const LineItemWrapper = styled(DisappearingTile).attrs(() => ({
  size: "sm",
  useMin: true,
  breakpoint: "md",
}))`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaMin("md")} {
    &:not(:last-child) {
      &::after {
        content: "";

        position: absolute;
        bottom: -24px;
        left: 0;

        width: 100%;
        height: 1px;

        background: ${colors.midnight}11;
      }
    }
  }
`;

export const SubItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 8px;
`;

export const SubItemBody = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0 4px ${ICON_WRAPPER_WIDTH + 12}px;

  &:empty {
    display: none;
  }

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 18px;

    width: 1px;
    height: 100%;

    border-left: 1px dashed ${colors.midnight}33;
  }
`;
