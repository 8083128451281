import React from "react";
import UILabel from "components/ui/UILabel";
import InfoBox from "components/ui/InfoBox";
import PaydayTimeline from "components/shared/PaydayTimeline";
import PaydayTimelineStatic from "components/shared/PaydayTimelineStatic";
import Page from "components/shared/ContentfulPage/Page";
import environment from "utils/environment";
import { RepaymentMethod } from "utils/api/user";
import { getUserProfile } from "store/api/apiSlice";
import * as Styled from "./styled";
import DashboardTile from "../../DashboardTile";

const PaydayTab = () => {
  const { data: userProfile } = getUserProfile();

  if (!userProfile) return null;

  const showFaqs =
    userProfile.repaymentMethod === RepaymentMethod.directDepositFlow;

  return (
    <Styled.Wrapper>
      <DashboardTile>
        <Styled.Main>
          {!environment.enableDemoTriggers && (
            <InfoBox>
              <strong>Coming soon:</strong> Track your paycheck live on payday.
            </InfoBox>
          )}
          <Styled.TimelineWrapper>
            {environment.enableDemoTriggers ? (
              <PaydayTimeline />
            ) : (
              <PaydayTimelineStatic />
            )}
          </Styled.TimelineWrapper>
        </Styled.Main>
      </DashboardTile>

      {showFaqs && (
        <Styled.FaqsWrapper>
          <UILabel.Small bold caps color="azure">
            Frequently Asked Questions
          </UILabel.Small>
          <Page
            slug={
              environment.enableDemoTriggers
                ? "payday-faqs-demo"
                : "payday-faqs"
            }
            section="account"
            noLayout
            preview
          />
        </Styled.FaqsWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default PaydayTab;
