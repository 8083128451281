import React from "react";
import styled, { css } from "styled-components";
import Button, { ButtonLevel } from "components/ui/Button";
import Icon, { IconName } from "components/ui/Icon";
import { Body, Heading } from "components/ui/Text";
import { colors } from "utils/theme";
import UILabel from "components/ui/UILabel";
import MobileFixedFooter from "components/ui/MobileFixedFooter";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  flex: 0 0 auto;

  border-radius: 9999px;
  background: ${colors.flax};
  color: ${colors.violet};
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ListItem = styled.li<{ $isComplete?: boolean }>`
  position: relative;

  display: flex;
  gap: 16px;

  &:not(:last-child) {
    &::after {
      content: "";

      position: absolute;
      left: 24px;
      top: 24px;
      z-index: -1;
      transform: translateX(-50%);

      width: 4px;
      height: 100%;

      background: ${colors.flax};
    }
  }

  ${({ $isComplete }) =>
    $isComplete &&
    css`
      &::after,
      ${IconWrapper} {
        background: ${colors.azure};
        color: white;
      }
    `};
`;

const List = styled.ol`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  list-style-type: none;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const INTRO_ITEMS: {
  icon: IconName;
  title: string;
  description: string;
  isComplete?: boolean;
}[] = [
  {
    title: "Sign up",
    description: "Create a Reset account",
    icon: "userCircle",
  },
  {
    title: "Link your checking account",
    description: "Securely connect your account with Plaid",
    icon: "bank",
  },
  {
    title: "Verify your income",
    description: "Connect your income source in seconds",
    icon: "income",
  },
  {
    title: "Submit your application",
    description: "Verify your identity to complete your application",
    icon: "clipboardListCheck",
  },
];

interface Props {
  hideIntro: () => void;
}

const Intro: React.FC<Props> = ({ hideIntro }) => (
  <Wrapper>
    <Header>
      <Heading.H3 tag="h1">
        Get daily access to your earned income in minutes.
      </Heading.H3>

      <Body>
        No interest, instant transfer fees, monthly fees, or late fees.
      </Body>
    </Header>

    <List>
      {INTRO_ITEMS.map((item) => (
        <ListItem key={item.icon} $isComplete={item.isComplete}>
          <IconWrapper>
            <Icon size="md" name={item.isComplete ? "checkmark" : item.icon} />
          </IconWrapper>

          <Text>
            <UILabel.XLarge bold>{item.title}</UILabel.XLarge>
            <UILabel.Large color="dust">{item.description}</UILabel.Large>
          </Text>
        </ListItem>
      ))}
    </List>

    <MobileFixedFooter>
      <Button onClick={hideIntro} level={ButtonLevel.cta}>
        Continue
      </Button>
    </MobileFixedFooter>
  </Wrapper>
);

export default Intro;
