import React from "react";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import { IconName } from "components/ui/Icon";
import relinkPayrollImage from "assets/images/general/relink-payroll.png";
import { ApiDashboardAccounts } from "utils/api/dashboard";
import {
  ApiNotificationSource,
  ApiNotificationType,
} from "utils/api/notifications";
import { DEFAULT_ACCOUNTS } from "./constants";
import * as Styled from "./styled";

const getAccountName = ({
  source,
  accounts = DEFAULT_ACCOUNTS,
}: {
  source?: ApiNotificationSource;
  accounts?: ApiDashboardAccounts;
}) => {
  switch (source) {
    case ApiNotificationSource.plaid:
      return accounts.primaryBank || DEFAULT_ACCOUNTS.primaryBank;
    case ApiNotificationSource.pinwheel:
      return accounts.payroll || DEFAULT_ACCOUNTS.payroll;
    default:
      return undefined;
  }
};

const getIntegrationErrorSuccessMessage = ({ source, accounts }) =>
  `${getAccountName({ source, accounts })} account reconnected!`;

interface NotificationUi {
  title: string;
  message: (params: {
    source?: ApiNotificationSource;
    accounts?: ApiDashboardAccounts;
  }) => string;
  image?: string;
  icon?: IconName;
  actions?: (params: {
    onClick?: () => void;
    onClickLearnMore?: () => void;
  }) => React.ReactNode[];
  modalMessage?: React.ReactNode;
  successMessage?: (params: {
    source?: ApiNotificationSource;
    accounts?: ApiDashboardAccounts;
  }) => string;
}

const PINWHEEL_INTEGRATION_ERROR_NOTIFICATION: NotificationUi = {
  title: "Your earnings amount may not be up-to-date",
  message: ({ source, accounts }) =>
    `${getAccountName({ source, accounts })} requires you to reconnect periodically to access the latest income data`,
  image: relinkPayrollImage,
  actions: ({ onClick, onClickLearnMore }) => [
    <Styled.ActionButton
      onClick={onClick}
      size={ButtonSize.md}
      level={ButtonLevel.cta}
    >
      Reconnect
    </Styled.ActionButton>,
    <Styled.ActionLinkButton onClick={onClickLearnMore}>
      Learn More
    </Styled.ActionLinkButton>,
  ],
  modalMessage: (
    <>
      <UILabel.Small bold caps color="azure">
        How Reset works
      </UILabel.Small>
      <p>
        Reset relies on ongoing data about your income and employment to provide
        early access to your earnings. To make sure we have the most up-to-date
        information, we'll periodically ask you to reconnect to your payroll
        account. How often this is necessary depends on your specific payroll
        provider. Visit the Reset app daily to ensure the spending limit is
        fully updated!
      </p>
    </>
  ),
  successMessage: getIntegrationErrorSuccessMessage,
};

const PLAID_INTEGRATION_ERROR_NOTIFICATION: NotificationUi = {
  title: "Reconnect your checking account",
  message: ({ source, accounts }) =>
    `${getAccountName({ source, accounts })} requires you to reconnect periodically to keep your balance and transactions up-to-date`,
  icon: "bank",
  successMessage: getIntegrationErrorSuccessMessage,
};

const OPTED_TO_SKIP_PAYROLL_LINKING_NOTIFICATION: NotificationUi = {
  title: "You might be eligible for a higher spending limit",
  message: () =>
    "Connecting your payroll account may allow you to access a larger portion of your earnings",
  image: relinkPayrollImage,
  actions: ({ onClick, onClickLearnMore }) => [
    <Styled.ActionButton
      onClick={onClick}
      size={ButtonSize.md}
      level={ButtonLevel.cta}
    >
      Connect
    </Styled.ActionButton>,
    <Styled.ActionLinkButton onClick={onClickLearnMore}>
      Learn More
    </Styled.ActionLinkButton>,
  ],
  modalMessage: (
    <>
      <UILabel.Small bold caps color="azure">
        How Reset works
      </UILabel.Small>
      <p>
        Reset uses Pinwheel to securely connect your payroll account and keep
        your data private. This has <strong>no impact</strong> on your direct
        deposit or the timing of your paycheck.
      </p>
    </>
  ),
};

const getIntegrationErrorNotificationUi = (source?: ApiNotificationSource) => {
  switch (source) {
    case ApiNotificationSource.plaid:
      return PLAID_INTEGRATION_ERROR_NOTIFICATION;
    case ApiNotificationSource.pinwheel:
      return PINWHEEL_INTEGRATION_ERROR_NOTIFICATION;
    default:
      return undefined;
  }
};

export const getNotificationUi: (props: {
  type: ApiNotificationType;
  source?: ApiNotificationSource;
}) => NotificationUi | undefined = ({ type, source }) => {
  switch (type) {
    case ApiNotificationType.integrationError:
      return getIntegrationErrorNotificationUi(source);
    case ApiNotificationType.optedToSkipPayrollLinking:
      return OPTED_TO_SKIP_PAYROLL_LINKING_NOTIFICATION;
    default:
      return undefined;
  }
};
