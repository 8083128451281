import { getHeaders } from "utils/api/common";
import {
  ApiAdminUser,
  ApiAdminUserAnnualizedIncome,
  ApiAdminUserDirectDeposit,
  ApiAdminUserPaveStatus,
  ApiAdminUserPinwheelStatus,
  ApiAdminUserPlaidStatus,
  ApiAdminUserPlaidTransactions,
  ApiAdminUsers,
  ApiAdminUsersRequest,
} from "utils/api/admin/user";
import { ApiAdminWaitlist } from "utils/api/admin/waitlist";
import baseApiSlice from "store/api/apiSlice";
import { ApiAdminCardTransactions } from "utils/api/admin/cardTransaction";
import { ApiAdminIntegrationErrors } from "utils/api/admin/integrationError";
import { decamelizeKeysDeep as decamelizeKeys } from "utils/object";
import { ApiAdminFinancialHealthSurveyResults } from "utils/api/admin/financialHealth";

const tagTypes = [
  "AdminUsers",
  "AdminUser",
  "AdminWaitlist",
  "AdminUserAnnualizedIncome",
  "AdminUserPaveStatus",
  "AdminUserPinwheelStatus",
  "AdminUserDirectDeposit",
  "AdminUserPlaidTransactions",
  "AdminUserPlaidStatus",
  "AdminUserUnresolvedIntegrationErrors",
  "AdminCardTransaction",
  "AdminFinancialHealthSurveyResults",
];

baseApiSlice.enhanceEndpoints({ addTagTypes: tagTypes });

const apiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminUsers: builder.query<ApiAdminUsers, ApiAdminUsersRequest>({
      query: ({ page = 1 }) => `/admin/users?page=${page}`,
      providesTags: ["AdminUsers"],
    }),
    getAdminUsersCardholders: builder.query<
      ApiAdminUsers,
      ApiAdminUsersRequest
    >({
      query: ({ page = 1, sortBy }) =>
        `/admin/card_accounts?page=${page}&sort_by=${sortBy}`,
      providesTags: ["AdminUsers"],
    }),
    getAdminUsersApplicants: builder.query<ApiAdminUsers, ApiAdminUsersRequest>(
      {
        query: ({ page = 1 }) => `/admin/card_applicants?page=${page}`,
        providesTags: ["AdminUsers"],
      }
    ),
    getAdminUsersEligible: builder.query<ApiAdminUsers, ApiAdminUsersRequest>({
      query: ({ page = 1 }) => `/admin/eligible_users?page=${page}`,
      providesTags: ["AdminUsers"],
    }),
    postAdminExportUserData: builder.mutation<void, void>({
      query: () => ({
        url: "/admin/export_user_data",
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    postAdminExportWaitlistData: builder.mutation<void, void>({
      query: () => ({
        url: "/admin/export_waitlist_data",
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    getAdminUser: builder.query<ApiAdminUser, string>({
      query: (id) => `/admin/users/${id}`,
      providesTags: ["AdminUser"],
    }),
    getAdminUserPinwheelStatus: builder.query<
      ApiAdminUserPinwheelStatus,
      string
    >({
      query: (id) => `/admin/users/${id}/pinwheel_status`,
      providesTags: ["AdminUserPinwheelStatus"],
    }),
    getAdminUserDirectDeposit: builder.query<ApiAdminUserDirectDeposit, string>(
      {
        query: (id) => `/admin/users/${id}/direct_deposit`,
        providesTags: ["AdminUserDirectDeposit"],
      }
    ),
    getAdminUserPlaidTransactions: builder.query<
      ApiAdminUserPlaidTransactions,
      string
    >({
      query: (id) => `/admin/users/${id}/plaid_transactions`,
      providesTags: ["AdminUserPlaidTransactions"],
    }),
    getAdminUserPlaidStatus: builder.query<ApiAdminUserPlaidStatus, string>({
      query: (id) => `/admin/users/${id}/plaid_status`,
      providesTags: ["AdminUserPlaidStatus"],
    }),
    getAdminWaitlist: builder.query<ApiAdminWaitlist, number>({
      query: (page = 1) => `/admin/waitlists?page=${page}`,
      providesTags: ["AdminWaitlist"],
    }),
    postAdminUserVerifyIdentity: builder.mutation<{ success: boolean }, string>(
      {
        query: (id) => ({
          url: `/admin/users/${id}/verify_identity`,
          method: "POST",
          headers: getHeaders(),
        }),
      }
    ),
    postAdminUserRefreshEligibility: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/refresh_eligibility`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["AdminUser"],
    }),
    postAdminUserDestroy: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}`,
        method: "DELETE",
        headers: getHeaders(),
      }),
    }),
    postAdminUserDisconnect: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/disconnect`,
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    postAdminUserImpersonate: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/impersonate`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["Dashboard", "UserProfile", "CardTransaction"],
    }),
    getAdminUserUnresolvedIntegrationErrors: builder.query<
      ApiAdminIntegrationErrors,
      string
    >({
      query: (id) => `/admin/users/${id}/unresolved_integration_errors`,
      providesTags: ["AdminUserUnresolvedIntegrationErrors"],
    }),
    postAdminIntegrationErrorResolve: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/integration_errors/${id}/resolve`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["AdminUserUnresolvedIntegrationErrors"],
    }),
    getAdminCardTransactions: builder.query<ApiAdminCardTransactions, number>({
      query: (page = 1) => `/admin/card_transactions?page=${page}`,
      providesTags: ["AdminCardTransaction"],
    }),
    postIssuePhysicalCard: builder.mutation<
      void,
      { id: string; expeditedFulfillment: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/users/${id}/issue_physical_card`,
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body),
      }),
    }),
    getAdminFinancialHealthSurveyResults: builder.query<
      ApiAdminFinancialHealthSurveyResults,
      number
    >({
      query: (page = 1) =>
        `/admin/financial_health/survey_results?page=${page}`,
      providesTags: ["AdminFinancialHealthSurveyResults"],
    }),
    getAdminUserAnnualizedIncome: builder.query<
      ApiAdminUserAnnualizedIncome,
      string
    >({
      query: (id) => `/admin/users/${id}/annualized_income`,
      providesTags: ["AdminUserAnnualizedIncome"],
    }),
    getAdminUserPaveStatus: builder.query<ApiAdminUserPaveStatus, string>({
      query: (id) => `/admin/users/${id}/pave_status`,
      providesTags: ["AdminUserPaveStatus"],
    }),
    postAdminUserOverrideCompensation: builder.mutation<
      void,
      {
        id: string;
        compensation_amount_int: number;
        pay_frequency: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/users/${id}/override_compensation`,
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body),
      }),
    }),
    postAdminUserOverridePayPeriod: builder.mutation<
      void,
      {
        id: string;
        pay_period_start_date: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/users/${id}/override_pay_period`,
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body),
      }),
    }),
    postAdminUserOverrideUserConfig: builder.mutation<
      void,
      {
        id: string;
        earned_wage_access_multiplier?: number;
        require_payroll_linking?: boolean;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/users/${id}/override_user_config`,
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body),
      }),
    }),
    postAdminUserDebitExternalAccount: builder.mutation<
      void,
      {
        id: string;
        effective_date: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/users/${id}/debit_external_account`,
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body),
      }),
    }),
    postAdminUserUnlinkBankAccount: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/unlink_bank_account`,
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    postAdminUserConvertPayrollLinkedToSkipped: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/users/${id}/convert_payroll_linked_to_skipped`,
        method: "POST",
        headers: getHeaders(),
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAdminUsersQuery,
  useGetAdminUsersCardholdersQuery,
  useGetAdminUsersApplicantsQuery,
  useGetAdminUsersEligibleQuery,
  usePostAdminExportUserDataMutation,
  usePostAdminExportWaitlistDataMutation,
  useGetAdminUserQuery,
  useGetAdminUserPinwheelStatusQuery,
  useGetAdminUserDirectDepositQuery,
  useGetAdminUserPlaidTransactionsQuery,
  useGetAdminUserPlaidStatusQuery,
  useGetAdminWaitlistQuery,
  usePostAdminUserVerifyIdentityMutation,
  usePostAdminUserRefreshEligibilityMutation,
  usePostAdminUserDestroyMutation,
  usePostAdminUserDisconnectMutation,
  usePostAdminUserImpersonateMutation,
  useGetAdminUserUnresolvedIntegrationErrorsQuery,
  usePostAdminIntegrationErrorResolveMutation,
  useGetAdminCardTransactionsQuery,
  usePostIssuePhysicalCardMutation,
  useGetAdminFinancialHealthSurveyResultsQuery,
  useGetAdminUserPaveStatusQuery,
  useGetAdminUserAnnualizedIncomeQuery,
  usePostAdminUserOverrideCompensationMutation,
  usePostAdminUserOverridePayPeriodMutation,
  usePostAdminUserOverrideUserConfigMutation,
  usePostAdminUserDebitExternalAccountMutation,
  usePostAdminUserUnlinkBankAccountMutation,
  usePostAdminUserConvertPayrollLinkedToSkippedMutation,
} = apiSlice;

export const getAdminUsers = (params, options?: {}) =>
  useGetAdminUsersQuery(params, options);

export const getAdminUsersCardholders = (params, options?: {}) =>
  useGetAdminUsersCardholdersQuery(params, options);

export const getAdminUsersApplicants = (params, options?: {}) =>
  useGetAdminUsersApplicantsQuery(params, options);

export const getAdminUsersEligible = (params, options?: {}) =>
  useGetAdminUsersEligibleQuery(params, options);

export const getAdminUser = (id, options?: {}) =>
  useGetAdminUserQuery(id, options);

export const getAdminUserPinwheelStatus = (id, options?: {}) =>
  useGetAdminUserPinwheelStatusQuery(id, options);

export const getAdminUserDirectDeposit = (id, options?: {}) =>
  useGetAdminUserDirectDepositQuery(id, options);

export const getAdminUserPlaidTransactions = (id, options?: {}) =>
  useGetAdminUserPlaidTransactionsQuery(id, options);

export const getAdminUserPlaidStatus = (id, options?: {}) =>
  useGetAdminUserPlaidStatusQuery(id, options);

export const getAdminUserUnresolvedIntegrationErrors = (id, options?: {}) =>
  useGetAdminUserUnresolvedIntegrationErrorsQuery(id, options);

export const getAdminWaitlist = (page, options?: {}) =>
  useGetAdminWaitlistQuery(page, options);

export const getAdminCardTransactions = (page, options?: {}) =>
  useGetAdminCardTransactionsQuery(page, options);

export const getAdminFinancialHealthSurveyResults = (page, options?: {}) =>
  useGetAdminFinancialHealthSurveyResultsQuery(page, options);

export const getAdminUserAnnualizedIncome = (id, options?: {}) =>
  useGetAdminUserAnnualizedIncomeQuery(id, options);

export const getAdminUserPaveStatus = (id, options?: {}) =>
  useGetAdminUserPaveStatusQuery(id, options);

export default apiSlice;
