import React from "react";
import styled from "styled-components";
import { Body, Heading } from "components/ui/Text";
import Tile from "components/ui/Tile";
import { colors } from "utils/theme";

const Wrapper = styled(Tile)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  strong {
    @supports (background-clip: text) {
      background-image: linear-gradient(
        75deg,
        ${colors.heliotrope},
        ${colors.azure}
      );
      color: transparent;
      background-clip: text;
    }
  }
`;

const IneligibleMessage = () => (
  <Wrapper size="xl">
    <Header>
      <Heading.H3 tag="h1">Thank you for your interest in Reset.</Heading.H3>
    </Header>

    <Main>
      <Body>
        Upon reviewing your linked accounts, they do not currently meet our
        eligibility criteria. Unfortunately, we're unable to move your
        application forward at this&nbsp;time.
      </Body>
    </Main>
  </Wrapper>
);

export default IneligibleMessage;
