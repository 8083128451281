import React, { useRef } from "react";
import { Heading } from "components/ui/Text";
import UILabel from "components/ui/UILabel";
import Icon from "components/ui/Icon";
import { ApiAdminUser } from "utils/api/admin/user";
import Button, { ButtonLevel } from "components/ui/Button";
import {
  usePostAdminUserConvertPayrollLinkedToSkippedMutation,
  usePostAdminUserDestroyMutation,
  usePostAdminUserDisconnectMutation,
  usePostAdminUserImpersonateMutation,
  usePostAdminUserUnlinkBankAccountMutation,
  usePostAdminUserVerifyIdentityMutation,
} from "store/admin/api/apiSlice";
import ConfirmActionWithValidationModal from "components/modal/ConfirmActionWithValidationModal";
import { ModalApi } from "components/modal/Modal";
import ShipCardModal from "components/modal/ShipCardModal";
import { VerificationStatus } from "types/verification";
import OverrideCompensationModal from "components/modal/OverrideCompensationModal";
import OverridePayPeriodModal from "components/modal/OverridePayPeriodModal";
import OverrideUserConfigModal from "components/modal/OverrideUserConfigModal";
import DebitExternalAccountModal from "components/modal/DebitExternalAccountModal";
import * as Styled from "./styled";

const VERIFICATION_STATUS_FLAGGED = [
  VerificationStatus.flaggedForReview,
  VerificationStatus.flaggedForInternalReview,
  VerificationStatus.flaggedForReviewWithDocument,
];

interface Props {
  user: ApiAdminUser;
  refetchUser: () => void;
}

const Danger: React.FC<Props> = ({ user, refetchUser }) => {
  const debitExternalAccountModalRef = useRef<ModalApi>(null);
  const overrideCompensationUserModalRef = useRef<ModalApi>(null);
  const overridePayPeriodUserModalRef = useRef<ModalApi>(null);
  const overrideUserConfigModalRef = useRef<ModalApi>(null);
  const unlinkBankAccountModalRef = useRef<ModalApi>(null);
  const convertPayrollLinkedToskippedModalRef = useRef<ModalApi>(null);
  const disconnectUserModalRef = useRef<ModalApi>(null);
  const deleteUserModalRef = useRef<ModalApi>(null);
  const verifyUserIdentityModalRef = useRef<ModalApi>(null);
  const shipCardModalRef = useRef<ModalApi>(null);
  const [postDisconnectUser] = usePostAdminUserDisconnectMutation();
  const [postDestroyUser] = usePostAdminUserDestroyMutation();
  const [verifyIdentity] = usePostAdminUserVerifyIdentityMutation();
  const [postUnlinkBankAccount] = usePostAdminUserUnlinkBankAccountMutation();
  const [postConvertPayrollLinkedToSkipped] =
    usePostAdminUserConvertPayrollLinkedToSkippedMutation();

  const [postImpersonateUser] = usePostAdminUserImpersonateMutation();
  const impersonateUser = async () => {
    await postImpersonateUser(user.id);
    window.location.replace("/dashboard");
  };

  const unlinkBankAccount = async () => {
    await postUnlinkBankAccount(user.id);
    window.location.reload();
  };

  const convertPayrollLinkedToskipped = async () => {
    await postConvertPayrollLinkedToSkipped(user.id);
    window.location.reload();
  };

  const disconnectUser = async () => {
    await postDisconnectUser(user.id);
    window.location.reload();
  };

  const deleteUser = async () => {
    await postDestroyUser(user.id);
    window.location.replace("/admin/users");
  };

  const verifyUserIdentity = async () => {
    verifyIdentity(user.id);
  };

  const onClickDebitExternalAccount = () => {
    debitExternalAccountModalRef.current?.show();
  };

  const onClickOverrideCompensation = () => {
    overrideCompensationUserModalRef.current?.show();
  };

  const onClickOverridePayPeriod = () => {
    overridePayPeriodUserModalRef.current?.show();
  };

  const onClickOverrideUserConfig = () => {
    overrideUserConfigModalRef.current?.show();
  };

  const onClickUnlinkBankAccount = () => {
    unlinkBankAccountModalRef.current?.show();
  };

  const onClickConvertPayrollLinkedToSkipped = () => {
    convertPayrollLinkedToskippedModalRef.current?.show();
  };

  const onClickDisonnect = () => {
    disconnectUserModalRef.current?.show();
  };

  const onClickDelete = () => {
    deleteUserModalRef.current?.show();
  };

  const onClickVerifyIdentity = () => {
    verifyUserIdentityModalRef.current?.show();
  };

  const onClickShipCard = () => {
    shipCardModalRef.current?.show();
  };

  const onSuccessShipCard = () => {
    refetchUser();
  };

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2" color="cerise">
        The Danger Zone
      </Heading.H4>

      <UILabel.Large>
        Exercise caution when taking the following user actions.
      </UILabel.Large>
      <Styled.TileActions>
        <Button level={ButtonLevel.ghost} onClick={impersonateUser}>
          <Icon name="userSecret" />
          View as User
        </Button>

        {VERIFICATION_STATUS_FLAGGED.includes(
          user.identityVerificationStatus
        ) && (
          <Button level={ButtonLevel.ghost} onClick={onClickVerifyIdentity}>
            <Icon name="glasses" />
            Manually Verify Identity
          </Button>
        )}

        {user.cards && !user.cards.physical && (
          <Button level={ButtonLevel.ghost} onClick={onClickShipCard}>
            <Icon name="creditCard" />
            Ship Physical Card
          </Button>
        )}

        <hr />
      </Styled.TileActions>

      <UILabel.Large>Repayment</UILabel.Large>
      <Styled.TileActions>
        <Styled.DangerGhostButton onClick={onClickDebitExternalAccount}>
          <Icon name="transfer" />
          Debit External Account
        </Styled.DangerGhostButton>

        <hr />
      </Styled.TileActions>

      <UILabel.Large>User Config Overrides</UILabel.Large>
      <Styled.TileActions>
        <Styled.DangerGhostButton onClick={onClickOverrideCompensation}>
          <Icon name="moneyBillWave" />
          Override Compensation
        </Styled.DangerGhostButton>

        <Styled.DangerGhostButton onClick={onClickOverridePayPeriod}>
          <Icon name="calendar" />
          Override Pay Period
        </Styled.DangerGhostButton>

        <Styled.DangerGhostButton onClick={onClickOverrideUserConfig}>
          <Icon name="userCircle" />
          Override User Config
        </Styled.DangerGhostButton>

        <hr />
      </Styled.TileActions>

      <UILabel.Large>Offboarding</UILabel.Large>
      <Styled.TileActions>
        <Styled.DangerGhostButton onClick={onClickUnlinkBankAccount}>
          <Icon name="bank" />
          Unlink Bank Account
        </Styled.DangerGhostButton>

        <Styled.DangerGhostButton
          onClick={onClickConvertPayrollLinkedToSkipped}
        >
          <Icon name="shuffle" />
          Convert Payroll Linked to Skipped
        </Styled.DangerGhostButton>

        <Styled.DangerGhostButton onClick={onClickDisonnect}>
          <Icon name="warningTriangle" />
          Disconnect user
        </Styled.DangerGhostButton>

        <Button level={ButtonLevel.danger} onClick={onClickDelete}>
          <Icon name="trash" />
          Delete user
        </Button>
      </Styled.TileActions>

      <DebitExternalAccountModal
        ref={debitExternalAccountModalRef}
        onSuccess={refetchUser}
        id={user.id}
      />

      <OverrideCompensationModal
        ref={overrideCompensationUserModalRef}
        onSuccess={refetchUser}
        id={user.id}
      />
      <OverridePayPeriodModal
        ref={overridePayPeriodUserModalRef}
        onSuccess={refetchUser}
        id={user.id}
      />
      <OverrideUserConfigModal
        ref={overrideUserConfigModalRef}
        onSuccess={refetchUser}
        user={user}
      />
      <ConfirmActionWithValidationModal
        ref={unlinkBankAccountModalRef}
        title="Unlink Bank Account"
        action={unlinkBankAccount}
        actionLabel={`Unlink ${user.name}'s bank account`}
        validationString={`unlink`}
        buttonLevel={ButtonLevel.danger}
      />
      <ConfirmActionWithValidationModal
        ref={convertPayrollLinkedToskippedModalRef}
        title="Convert Payroll Linked to Skipped"
        action={convertPayrollLinkedToskipped}
        actionLabel={`Convert ${user.name}'s linked payroll to skipped`}
        validationString={`convert`}
        buttonLevel={ButtonLevel.danger}
      />
      <ConfirmActionWithValidationModal
        ref={disconnectUserModalRef}
        title="Disconnect user"
        action={disconnectUser}
        actionLabel={`Disconnect ${user.name}`}
        validationString={`disconnect ${user.name}`}
        buttonLevel={ButtonLevel.danger}
      />
      <ConfirmActionWithValidationModal
        ref={deleteUserModalRef}
        title="Delete user"
        action={deleteUser}
        actionLabel={`Delete ${user.name}`}
        validationString={`delete ${user.name}`}
        buttonLevel={ButtonLevel.danger}
      />
      <ConfirmActionWithValidationModal
        ref={verifyUserIdentityModalRef}
        title="Manually verify user identity"
        action={verifyUserIdentity}
        actionLabel={`Verified ${user.name}`}
        validationString={`verified ${user.name}`}
        buttonLevel={ButtonLevel.danger}
      />
      <ShipCardModal
        ref={shipCardModalRef}
        onSuccess={onSuccessShipCard}
        id={user.id}
      />
    </Styled.SectionTile>
  );
};

export default Danger;
