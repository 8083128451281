import React, { useEffect, useState } from "react";
import { DrawerApi } from "components/layout/DrawerLayout";
import { getIntegrationErrors } from "utils/notifications";
import { getNotifications, getUserProfile } from "store/api/apiSlice";
import { POLLING_INTERVAL } from "utils/api/common";
import Loading from "components/ui/Loading";
import SidebarInfo from "components/ui/SidebarInfo";
import { OnboardingTaskName } from "types/onboarding";
import TermsAcceptance from "./TermsAcceptance";
import IdentityVerification from "./IdentityVerification";
import ErrorResolution from "./ErrorResolution";
import { InfoType } from "./types";
import { getInfo } from "./utils";
import IneligibleMessage from "./IneligibleMessage";
import Repayment from "./Repayment";
import UnconfirmedMessage from "./UnconfirmedMessage";

interface Props {
  id: OnboardingTaskName;
  drawer: DrawerApi;
}

const CardApplicationStep: React.FC<Props> = ({ id, drawer }) => {
  const { data: userProfile, isLoading } = getUserProfile();
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);
  const { data: notifications, refetch: refetchNotifications } =
    getNotifications({
      pollingInterval,
    });
  const integrationErrors = getIntegrationErrors(notifications || []);

  const showInfo = (infoType: InfoType) => () => {
    drawer.openWith(
      <SidebarInfo closeDrawer={drawer.close} {...getInfo(infoType)} />
    );
  };

  useEffect(() => {
    if (integrationErrors?.length === 0) {
      setPollingInterval(POLLING_INTERVAL);
    }
  }, [integrationErrors]);

  if (integrationErrors.length) {
    return (
      <ErrorResolution
        refetch={refetchNotifications}
        integrationErrors={integrationErrors}
        setPollingInterval={setPollingInterval}
      />
    );
  }

  if (isLoading) return <Loading />;

  if (!userProfile.confirmed) return <UnconfirmedMessage />;

  if (!userProfile.eligibleForCard) return <IneligibleMessage />;

  switch (id) {
    case OnboardingTaskName.termsAcceptance:
      return <TermsAcceptance showInfo={showInfo} />;
    case OnboardingTaskName.identityVerification:
      return <IdentityVerification showInfo={showInfo} />;
    case OnboardingTaskName.depositSwitching:
    case OnboardingTaskName.repaymentAccountAcknowledgement:
      return <Repayment id={id} />;
    default:
      return <></>;
  }
};

export default CardApplicationStep;
