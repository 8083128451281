import React from "react";
import { hasDslUpdatingNotification } from "utils/notifications";
import { MarqetaIds } from "utils/hooks/useMarqeta";
import { getNotifications } from "store/api/apiSlice";
import CardFront from "./CardFront";
import CardBack from "./CardBack";
import * as Styled from "./styled";

interface Props {
  amount: number;
  nextPayday?: string;
  hideDetails: () => void;
  showDetails: () => void;
  isShownDetails: boolean;
  isLoadingDetails: boolean;
  cardElementIds: MarqetaIds;
  showDslZeroModal: () => void;
}

const ResetCard = ({
  amount,
  nextPayday,
  hideDetails,
  showDetails,
  isShownDetails,
  isLoadingDetails,
  cardElementIds,
  showDslZeroModal,
}: Props) => {
  const positiveAmount = Math.max(amount, 0);
  const { data: notifications } = getNotifications();
  const isUpdatingDsl = hasDslUpdatingNotification(notifications);
  const isActive = positiveAmount > 0;

  return (
    <Styled.Wrapper
      $showDetails={isShownDetails && !isLoadingDetails}
      $isActive={isActive}
      $isUpdating={isUpdatingDsl}
    >
      <Styled.CardWrapper>
        <Styled.FrontWrapper>
          <CardFront
            amount={positiveAmount}
            isActive={isActive}
            isUpdating={isUpdatingDsl}
            nextPayday={nextPayday}
            showDetails={showDetails}
            isLoadingDetails={isLoadingDetails}
            showDslZeroModal={showDslZeroModal}
          />
        </Styled.FrontWrapper>

        <Styled.BackWrapper>
          <CardBack
            hideDetails={hideDetails}
            isActive={isActive}
            ids={cardElementIds}
            isShownDetails={isShownDetails}
          />
        </Styled.BackWrapper>
      </Styled.CardWrapper>
    </Styled.Wrapper>
  );
};

export default ResetCard;
