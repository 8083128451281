import styled, { css } from "styled-components";
import Tag from "components/ui/Tag";
import { colors, mediaMax } from "utils/theme";
import { Partner } from "utils/partners/types";

const getStatusTagStyles = ({ $partner }: { $partner: Partner }) => {
  const { banner } = $partner;

  if (banner) {
    const { theme } = banner;

    return css`
      background: ${theme.background};
      color: ${theme.color};
    `;
  }

  return "";
};

export const Wrapper = styled(Tag)<{ $partner: Partner }>`
  width: fit-content;

  color: ${colors.midnight};
  background: ${colors.flax};

  ${getStatusTagStyles}

  ${mediaMax("lg")} {
    margin-top: 6px;
  }
`;
