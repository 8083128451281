import Loading from "components/ui/Loading";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { getAdminUsersCardholders } from "store/admin/api/apiSlice";
import Pagination from "components/ui/Pagination";
import { getUserProfile } from "store/api/apiSlice";
import { RepaymentMethod } from "utils/api/user";
import UILabel from "components/ui/UILabel";
import * as Styled from "./styled";
import Table from "../../components/Table";
import {
  getColumnValue,
  isOtherAdmin,
} from "../../components/users-table-utils";

const getQueryString = (params) =>
  Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const COLUMN_HEADING_MAP = {
  id: "ID",
  name: "Name",
  email: "Email",
  admin: "Admin",
  repaymentMethod: "Repayment Method",
  payDate: "Pay Date",
  payDates: "Pay Dates",
};

const COLUMNS = [
  "id",
  "name",
  "email",
  "admin",
  "repaymentMethod",
  "payDate",
  "payDates",
];

const FILTERS = [
  { label: "All", group: null },
  {
    label: "Direct Deposit",
    group: RepaymentMethod.directDepositFlow,
  },
  {
    label: "ACH Debit",
    group: RepaymentMethod.achDebitFlow,
  },
];

const RepaymentSchedule = () => {
  const [params] = useSearchParams();
  const page = parseInt(params.get("page"), 10) || 1;
  const userGroup = params.get("user_group");

  const requestParams = {
    page,
    sortBy: "pay_date",
  };

  const { data: cardholdersData, isLoading: isLoadingCardholders } =
    getAdminUsersCardholders(requestParams);
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();

  if (isLoadingCardholders || isLoadingUserProfile) return <Loading />;

  const { users, totalPages } = cardholdersData || {};

  // NOTE:: Backend filtering requires us to store the repayment method in the DB. This is a lower lift short term solution.
  const filteredUsers = users.filter((user) => {
    if (!userGroup) return true;
    return user.userConfig.repaymentMethod === userGroup;
  });

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.HeaderSection>
          <UILabel.Medium>Show:</UILabel.Medium>
          {FILTERS.map((filter) => (
            <Styled.HeaderFilter
              key={`${filter.group}`}
              to={`?${getQueryString({ user_group: filter.group })}`}
              $isActive={userGroup === filter.group}
            >
              {filter.label}
            </Styled.HeaderFilter>
          ))}
        </Styled.HeaderSection>
      </Styled.Header>

      <Table>
        <thead>
          <tr>
            {COLUMNS.map((name) => (
              <th key={name}>{COLUMN_HEADING_MAP[name]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              {COLUMNS.map((name, index) => (
                <td key={index} data-heading={COLUMN_HEADING_MAP[name]}>
                  {getColumnValue({
                    user,
                    isOtherAdmin: isOtherAdmin(user, userProfile),
                    name,
                  })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {totalPages > 1 && <Pagination current={page} total={totalPages} />}
    </Styled.Wrapper>
  );
};

export default RepaymentSchedule;
