import React, { useRef } from "react";
import UILabel from "components/ui/UILabel";
import Modal, { ModalApi } from "components/modal/Modal";
import { ApiNotification } from "utils/api/notifications";
import { ApiDashboardAccounts } from "utils/api/dashboard";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";
import { getNotificationUi } from "./utils";

interface Props {
  notification: ApiNotification;
  accounts: ApiDashboardAccounts;
  onClick?: () => void;
  isSuccess?: boolean;
}

const ListItem: React.FC<Props> = ({
  notification,
  accounts,
  onClick,
  isSuccess,
}) => {
  const modalRef = useRef<ModalApi>(null);

  const onClickLearnMore = () => {
    modalRef.current?.show();
  };

  const { params, type } = notification;
  const { source } = params;
  const notificationUi = getNotificationUi({ source, type });

  if (!notificationUi) return null;

  const { title, message, image, icon, actions, modalMessage, successMessage } =
    notificationUi;
  const messageText = message({ source, accounts });

  if (isSuccess) {
    return (
      <Styled.ListItem>
        <Styled.IconWrapper $color="leaf">
          <Icon name="party" color="violet" size="lg" />
        </Styled.IconWrapper>
        <Styled.Main>
          <UILabel.Large bold>
            {successMessage({ source, accounts }) || "Success!"}
          </UILabel.Large>
        </Styled.Main>
      </Styled.ListItem>
    );
  }

  return (
    <>
      <Styled.ListItem>
        {image && <Styled.Image src={image} alt={messageText} />}
        {icon && (
          <Styled.IconWrapper>
            <Icon name={icon} color="white" size="lg" />
          </Styled.IconWrapper>
        )}
        <Styled.Main>
          <Styled.Content>
            <Styled.Text>
              <UILabel.Large bold>{title}</UILabel.Large>
              <UILabel.Medium>{messageText}</UILabel.Medium>
            </Styled.Text>

            {image && (
              <Styled.MobileImage>
                <img src={image} alt={messageText} />
              </Styled.MobileImage>
            )}
          </Styled.Content>

          {actions && (
            <Styled.Actions>
              {actions({ onClick, onClickLearnMore }).map((action, index) => (
                <React.Fragment key={index}>{action}</React.Fragment>
              ))}
            </Styled.Actions>
          )}
        </Styled.Main>

        {actions ? (
          <Modal ref={modalRef}>
            <Styled.ModalBody>{modalMessage}</Styled.ModalBody>
          </Modal>
        ) : (
          <>
            <Styled.ArrowIcon>
              <Icon name="caretRightSolid" color="azure" />
            </Styled.ArrowIcon>
            <Styled.ListItemButton onClick={onClick} />
          </>
        )}
      </Styled.ListItem>
    </>
  );
};

export default ListItem;
