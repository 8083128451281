import React, { useEffect, useState } from "react";
import { Heading } from "components/ui/Text";
import { ApiAdminUser } from "utils/api/admin/user";
import { getAdminUserPaveStatus } from "store/admin/api/apiSlice";
import Button from "components/ui/Button";
import styled from "styled-components";
import * as Styled from "./styled";

interface Props {
  user: ApiAdminUser;
}

const StyledPre = styled.pre`
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

const Pave: React.FC<Props> = ({ user }) => {
  const [showPaveStatus, setShowPaveStatus] = useState(false);
  const {
    data: adminUserPaveStatus,
    isLoading: isLoadingPaveStatus,
    isError: isErrorPaveStatus,
  } = getAdminUserPaveStatus(user.id);

  useEffect(() => {
    setShowPaveStatus(false);
  }, [user]);

  if (isErrorPaveStatus) {
    return (
      <Styled.SectionTile>
        <Heading.H4 tag="h2">Pave account not found</Heading.H4>
      </Styled.SectionTile>
    );
  }

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Pave</Heading.H4>
      {showPaveStatus && (
        <Styled.Info>
          <Styled.InfoSection>
            <Heading.H4>Characteristics</Heading.H4>
            <StyledPre>
              {JSON.stringify(
                adminUserPaveStatus.characteristics,
                undefined,
                2
              )}
            </StyledPre>

            <Heading.H4>Scores</Heading.H4>
            <StyledPre>
              {JSON.stringify(adminUserPaveStatus.scores, undefined, 2)}
            </StyledPre>

            <Heading.H4>Incomes</Heading.H4>
            <StyledPre>
              {JSON.stringify(adminUserPaveStatus.incomes, undefined, 2)}
            </StyledPre>
          </Styled.InfoSection>
        </Styled.Info>
      )}
      {!showPaveStatus && (
        <Styled.ButtonWrapper>
          <Button
            isLoading={isLoadingPaveStatus}
            onClick={() => setShowPaveStatus(true)}
          >
            Show Pave Status
          </Button>
        </Styled.ButtonWrapper>
      )}
    </Styled.SectionTile>
  );
};

export default Pave;
