import React from "react";
import { Heading } from "components/ui/Text";
import IntegrationErrors from "components/shared/IntegrationErrors";
import { ApiNotification } from "utils/api/notifications";
import * as Styled from "./styled";

interface Props {
  integrationErrors: ApiNotification[];
  refetch: () => void;
  setPollingInterval: (interval: number) => void;
}

const ErrorResolution: React.FC<Props> = ({
  integrationErrors,
  refetch,
  setPollingInterval,
}) => {
  const onRelinkSuccess = () => {
    setPollingInterval(5_000);
    refetch();
  };

  return (
    <>
      <Styled.Header>
        <Heading.H3 tag="h1">
          Please resolve the following issues before proceeding with your
          application.
        </Heading.H3>
      </Styled.Header>

      <IntegrationErrors
        errors={integrationErrors}
        onSuccess={onRelinkSuccess}
      />
    </>
  );
};

export default ErrorResolution;
