import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  InputField,
  SearchInputField,
  RadioField,
  TileRadioGroupField,
} from "components/form";
import { Body } from "components/ui/Text";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import InfoBox from "components/ui/InfoBox";
import { LinkButton } from "components/ui/Link";
import Icon from "components/ui/Icon";
import SignupPayrollHelpModal from "components/modal/SignupPayrollHelpModal";
import environment from "utils/environment";
import {
  searchInstitutions,
  searchTimeAndAttendance,
} from "utils/api/pinwheel";
import { SearchInputSelection } from "types/general";
import { Eligibility, IncomeType } from "types/signup";
import { INCOME_OPTIONS, PAYROLL_FAVORITES } from "./constants";
import { CONDITIONS, isPayrollAllowed, pathIncludes } from "./utils";
import * as Styled from "./styled";

interface Props {
  initialValue?: SearchInputSelection;
  setEligibility: (eligibility: Eligibility) => void;
  setHasError: (hasError: boolean) => void;
}

interface PayrollProvider {
  name: string;
  id: string;
  data: {
    products: string[];
  };
}

const PayrollQuestionnaire: React.FC<Props> = ({
  initialValue,
  setEligibility,
  setHasError,
}) => {
  const [searchInputSelection, setSearchInputSelection] =
    useState(initialValue);
  const [payroll, setPayroll] = useState<PayrollProvider | undefined>(
    undefined
  );
  const [timeAndAttendance, setTimeAndAttendance] = useState<
    PayrollProvider | undefined
  >(undefined);
  const payrollModal = useRef(null);
  const [path, setPath] = useState<string[]>(["root"]);

  const { register, setValue, getValues, watch } = useFormContext();
  register("payroll");
  register("timeAndAttendance");
  const incomeInput = watch("questionnaire.incomeType");
  const trackHoursInput = watch("questionnaire.tracksHoursWithPayroll");

  const onSelectPayroll = (item, searchValue) => {
    setValue("payroll", item);
    setPayroll(item || undefined);
    if (item.id === "other") {
      setValue("payroll.otherName", searchValue);
    }
    if (item) {
      setHasError(false);
    }
  };

  const onSelectTimeAndAttendance = (item, searchValue) => {
    setValue("timeAndAttendance", item);
    setTimeAndAttendance(item || undefined);
    if (item.id === "other") {
      setValue("timeAndAttendance.otherName", searchValue);
    }
    if (item) {
      setHasError(false);
    }
  };

  const updateEligibility = () => {
    let outcome;
    const currentStep = ["root"];
    while (
      ![
        Eligibility.eligible,
        Eligibility.ineligible,
        Eligibility.indeterminate,
      ].includes(outcome)
    ) {
      const condition = CONDITIONS[currentStep.join(".")];
      if (condition) {
        outcome = condition({ products: payroll?.data.products, getValues });
        currentStep.push(outcome);
      } else {
        outcome = Eligibility.indeterminate;
      }

      if (payroll?.id) {
        outcome = isPayrollAllowed(payroll.id)
          ? outcome
          : Eligibility.ineligible;
      }
    }
    setPath(currentStep);
    setEligibility(outcome);
  };

  const transformPayrollSearchString = (str) => {
    if (["ssd", "ssdi"].some((term) => term === str.toLowerCase())) {
      return `${str} social security`;
    }

    return str;
  };

  const onSelectFavorite = (favorite: SearchInputSelection) => {
    setSearchInputSelection(favorite);
  };

  useEffect(() => {
    updateEligibility();
  }, [payroll, incomeInput, trackHoursInput]);

  const incomeType = getValues("questionnaire.incomeType");

  return (
    <>
      <Styled.Field>
        <Body tag="h3">I get paid...</Body>
        <TileRadioGroupField
          name="questionnaire.incomeType"
          options={INCOME_OPTIONS}
        />
      </Styled.Field>

      {incomeType && (
        <Styled.Field>
          <Styled.FieldBody>
            <Body tag="h3">
              {incomeType === IncomeType.benefits
                ? "I receive benefits from..."
                : "My payroll provider or employer is..."}
            </Body>

            <LinkButton onClick={() => payrollModal.current?.show()}>
              <Icon name="questionCircle" />
              Help
            </LinkButton>
          </Styled.FieldBody>

          <SearchInputField
            initialValue={searchInputSelection}
            onSelect={onSelectPayroll}
            getSearch={searchInstitutions}
            errorMessage="Please select a provider from the list"
            name="payrollName"
            required
            transformSearchString={transformPayrollSearchString}
          />

          {!payroll && (
            <Styled.Favorites className="notranslate">
              <UILabel.Small bold color="dust">
                Common providers
              </UILabel.Small>
              <Styled.FavoritesList key={incomeType}>
                {PAYROLL_FAVORITES[incomeType].map(
                  (favorite: SearchInputSelection) => (
                    <Button
                      key={favorite.id}
                      level={ButtonLevel.ghost}
                      size={ButtonSize.sm}
                      onClick={() => onSelectFavorite(favorite)}
                      type="button"
                    >
                      {favorite.searchString}
                    </Button>
                  )
                )}
              </Styled.FavoritesList>
            </Styled.Favorites>
          )}

          {payroll?.id === "other" && (
            <>
              <InfoBox title="Important note">
                If your employer isn't in the list, try searching for your
                payroll provider. Otherwise, you can enter the name of your
                employer or payroll provider below.
              </InfoBox>

              <InputField
                name="payroll.otherName"
                label="Other payroll provider or employer"
                required
              />
            </>
          )}
        </Styled.Field>
      )}

      {environment.signupFormDebugMode &&
        process.env.NODE_ENV === "development" &&
        payroll?.data.products && <div>{payroll.data.products.join(", ")}</div>}

      {pathIncludes(path, "root.hourly.yes") && (
        <Styled.Field>
          <Body tag="h3">I log my hours with...</Body>
          <RadioField
            name="questionnaire.tracksHoursWithPayroll"
            label=""
            required
            options={[
              {
                value: "yes",
                label: getValues("payroll.name"),
              },
              {
                value: "no",
                label: <>A different platform</>,
              },
            ]}
          />

          {pathIncludes(path, "root.hourly.yes.no") && (
            <>
              <SearchInputField
                onSelect={onSelectTimeAndAttendance}
                getSearch={searchTimeAndAttendance}
                label="Time and attendance platform"
                errorMessage="Please select a platform from the list"
                name="timeAndAttendanceName"
                required
              />

              {timeAndAttendance?.id === "other" && (
                <InputField
                  name="timeAndAttendance.otherName"
                  label="Other time and attendance platform"
                  required
                />
              )}
            </>
          )}
        </Styled.Field>
      )}

      <SignupPayrollHelpModal ref={payrollModal} />
    </>
  );
};

export default PayrollQuestionnaire;
