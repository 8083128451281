import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import FormContainer, {
  InputField,
  SelectField,
  SubmitButton,
} from "components/form";
import { usePostAdminUserOverrideCompensationMutation } from "store/admin/api/apiSlice";
import { ApiPayFrequency } from "utils/api/admin/user";
import { Heading } from "components/ui/Text";
import Modal, { ModalApi } from "./Modal";

export const PAY_FREQUENCY_OPTIONS = [
  { name: "Weekly", value: ApiPayFrequency.weekly },
  { name: "Biweekly", value: ApiPayFrequency.biweekly },
  { name: "Semimonthly", value: ApiPayFrequency.semimonthly },
  { name: "Monthly", value: ApiPayFrequency.monthly },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface Props {
  id: string;
  onSuccess: () => void;
}

const OverrideCompensationModal = (
  { id, onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postOverrideCompensation, postOverrideCompensationContext] =
    usePostAdminUserOverrideCompensationMutation();

  const onSubmit = (values: {
    compensationAmount: string;
    payFrequency: ApiPayFrequency;
  }) => {
    const compensationAmountInt = Math.round(
      parseInt(values.compensationAmount, 10) * 100
    );

    const overrideParams: {
      id: string;
      compensation_amount_int: number;
      pay_frequency: ApiPayFrequency;
    } = {
      id,
      compensation_amount_int: compensationAmountInt,
      pay_frequency: values.payFrequency,
    };

    postOverrideCompensation(overrideParams);
  };

  useEffect(() => {
    if (postOverrideCompensationContext.isSuccess && onSuccess) {
      onSuccess();
    }

    ref.current?.dismiss();
  }, [postOverrideCompensationContext.isSuccess]);

  return (
    <Modal ref={ref}>
      <Wrapper>
        <Heading.H4 tag="h2">Override Compensation</Heading.H4>

        <FormContainer onSubmit={onSubmit}>
          <InputField
            name="compensationAmount"
            label="Annualized Compensation Amount (USD)"
            type="number"
            required
          />

          <SelectField
            name="payFrequency"
            label="Frequency"
            options={PAY_FREQUENCY_OPTIONS}
            defaultValue={ApiPayFrequency.biweekly}
            required
          />

          <SubmitButton>Override Compensation</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(OverrideCompensationModal);
