import { FormSection as BaseFormSection } from "components/form";
import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 96px);
  gap: 36px;

  ${mediaMax("lg")} {
    min-height: calc(100vh - 190px);
    gap: 24px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 36px;

  ${mediaMax("lg")} {
    gap: 24px;
  }
`;

export const FormSection = styled(BaseFormSection)`
  position: relative;

  gap: 36px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FieldBody = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: flex-end;

  button {
    padding-bottom: 2px;
  }
`;
