import React, { useState } from "react";
import CardFront from "components/shared/ResetCard/CardFront";
import { Heading } from "components/ui/Text";
import Button from "components/ui/Button";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { getUserProfile } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import * as Styled from "./styled";
import ProbationarySteps from "./ProbationarySteps";
import BasicExplanation from "./BasicExplanation";

interface Props {
  setShowDslExplanation: (showDslExplanation: boolean) => void;
  safeToSpend: number;
  maxSafeToSpend: number;
}

const DslExplanation: React.FC<Props> = ({
  setShowDslExplanation,
  safeToSpend,
  maxSafeToSpend,
}) => {
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();
  const [currentStep, setCurrentStep] = useState(0);

  const updateStep = (index: number) => {
    setCurrentStep(index);
  };

  const amount = currentStep === 0 ? safeToSpend : maxSafeToSpend;

  if (isLoadingUserProfile) return <Loading />;

  return (
    <Styled.Wrapper>
      <Heading.H2 tag="h1">How it works</Heading.H2>

      <Styled.CardWrapper>
        <CardFront amount={amount || 0} isActive />
      </Styled.CardWrapper>

      {userProfile?.enableProbationaryLimit ? (
        <ProbationarySteps updateStep={updateStep} currentStep={currentStep} />
      ) : (
        <BasicExplanation />
      )}

      <MobileFixedFooter>
        <Button onClick={() => setShowDslExplanation(false)}>Continue</Button>
      </MobileFixedFooter>
    </Styled.Wrapper>
  );
};

export default DslExplanation;
