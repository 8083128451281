import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import Tile from "components/cms/components/block/Tile";
import { FEATURES } from "components/views/Home/constants";
import { BlockType } from "types/cms";
import * as Styled from "./styled";

const FeaturesSection = () => (
  <Styled.Wrapper>
    <ContentLayout>
      <Tile type={BlockType.tile} id="features" data={FEATURES} />
    </ContentLayout>
  </Styled.Wrapper>
);

export default FeaturesSection;
