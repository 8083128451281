import styled from "styled-components";
import Button, { BaseButton } from "components/ui/Button";
import { LinkButton } from "components/ui/Link";
import { Body } from "components/ui/Text";
import { colors, mediaMax, shadows } from "utils/theme";

export const List = styled.ul`
  display: grid;
  gap: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: fit-content;

  & > li {
    display: block;
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 136px;
  margin-left: -56px;
  margin-right: -8px;
`;

export const IconWrapper = styled.div<{ $color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  flex: 0 0 auto;
  margin: 24px 0 24px 24px;
  align-self: flex-start;

  border-radius: 12px;
  background: ${(props) =>
    colors[props.$color] || props.$color || colors.heliotrope};
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;

export const Content = styled.div`
  display: flex;
  gap: 12px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MobileImage = styled.div`
  position: relative;

  display: none;
  width: 96px;
  margin-right: -24px;
  flex: 0 0 auto;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateY(-12px);

    width: 100%;
  }
`;

export const ActionButton = styled(Button)``;

export const ActionLinkButton = styled(LinkButton)``;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const ListItemButton = styled(BaseButton)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

export const ArrowIcon = styled.div`
  margin-right: 16px;
  margin-left: -8px;
  order: 2;

  opacity: 0.5;
`;

export const ModalBody = styled(Body).attrs(() => ({ tag: "div" }))`
  text-align: left;
`;

export const ListItem = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 0;

  border-radius: 12px;
  background: color-mix(in srgb, ${colors.azure} 10%, white);
  border: 1px solid ${colors.violet};
  box-shadow: ${shadows.tile};

  text-align: left;

  ${mediaMax("md")} {
    ${Image} {
      display: none;
    }

    ${MobileImage} {
      display: block;
    }

    ${IconWrapper} {
      display: none;
    }

    ${Main} {
      padding: 16px;
      gap: 24px;
    }
  }
`;
