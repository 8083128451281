import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import Button from "components/ui/Button";
import { Body, Heading } from "components/ui/Text";
import { LinkButton } from "components/ui/Link";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

interface Props {
  action: () => void;
  actionLabel?: string;
  cancelLabel?: string;
  title?: React.ReactNode;
  body?: React.ReactNode;
  isLoading?: boolean;
}

const ConfirmActionModal = (
  {
    action,
    actionLabel = "Confirm",
    cancelLabel = "Cancel",
    title,
    body,
    isLoading,
  }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);

  const confirmAction = async () => {
    await action();
    ref.current?.dismiss();
  };

  return (
    <Modal ref={ref}>
      <form>
        <Wrapper>
          {title && <Heading.H4 tag="h2">{title}</Heading.H4>}

          {body && <Body tag="div">{body}</Body>}

          <Footer>
            <Button
              onClick={confirmAction}
              type="button"
              fullWidth
              isLoading={isLoading}
            >
              {actionLabel}
            </Button>

            <LinkButton
              size="lg"
              onClick={() => ref.current?.dismiss()}
              disabled={isLoading}
            >
              {cancelLabel}
            </LinkButton>
          </Footer>
        </Wrapper>
      </form>
    </Modal>
  );
};

export default forwardRef(ConfirmActionModal);
