import React from "react";
import { Partner } from "utils/partners/types";
import { PARTNERS } from "utils/partners/constants";
import * as Styled from "./styled";

const mapPartnerToLabel = (partner: Partner) => {
  switch (partner.slug) {
    case "national-debt-relief":
      return "NDR";
    default:
      return partner.name;
  }
};

interface Props {
  slug: string;
}

const PartnerTag: React.FC<Props> = ({ slug }) => {
  const partner = PARTNERS[slug];

  if (!partner) return null;

  return (
    <Styled.Wrapper $partner={partner}>
      {mapPartnerToLabel(partner)}
    </Styled.Wrapper>
  );
};

export default PartnerTag;
