import React, { useEffect, useState } from "react";
import { EventType, trackEvent } from "utils/analytics";
import { OnboardingStatus } from "utils/api/onboarding";
import { usePostQualifierMutation } from "store/api/apiSlice";
import ManualEntry from "./ManualEntry";
import WaitlistMessage from "./WaitlistMessage";
import PayrollLinking from "./PayrollLinking";

interface Props {
  onboardingStatus: OnboardingStatus;
  setIsPolling: (isPolling: boolean) => void;
}

const Payroll: React.FC<Props> = ({ onboardingStatus, setIsPolling }) => {
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_postWaitlist, postWaitlistContext] = usePostQualifierMutation({
    fixedCacheKey: "waitlist",
  });

  const onPayrollLinkSuccess = () => {
    trackEvent(EventType.userSignupSuccessful, {
      eventCallback: () => window.location.reload(),
    });
  };

  useEffect(() => {
    setIsPolling(!isManualEntry);
  }, [isManualEntry]);

  if (postWaitlistContext.isSuccess)
    return <WaitlistMessage isEligible={isEligible} />;

  if (isManualEntry) return <ManualEntry setIsEligible={setIsEligible} />;

  return (
    <PayrollLinking
      onSuccess={onPayrollLinkSuccess}
      onboardingStatus={onboardingStatus}
      setIsPolling={setIsPolling}
      setIsManualEntry={setIsManualEntry}
    />
  );
};

export default Payroll;
