import React from "react";
import styled from "styled-components";
import FormContainer from "components/form/FormContainer";
import OnboardingFlow from "components/shared/OnboardingFlow";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import { usePostUserMutation } from "store/api/apiSlice";
import { getApiErrors } from "utils/api/common";
import { mediaMin } from "utils/theme";
import { PartnerSlug } from "types/general";
import SignupForm from "./Form";
import { getSignupBody } from "./utils";
import { SignupFormData } from "./types";

const DEFAULT_VALUES = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
  },
};

const LogoWrapper = styled.div`
  margin: 48px auto 0;

  ${mediaMin("lg")} {
    display: none;
  }
`;

const View: React.FC = () => {
  const [postSignup, postSignupContext] = usePostUserMutation({
    fixedCacheKey: "signup",
  });
  const partnerSlug = sessionStorage.getItem("partner") as PartnerSlug;

  const onSubmit = async (values: SignupFormData) => {
    if (values.user?.email?.length > 255) return;

    const body = getSignupBody(values);

    await postSignup(body);
  };

  return (
    <OnboardingFlow
      sidebarNavigation={[
        {
          text: "Log In",
          icon: "userCircle",
          href: "/users/sign_in",
        },
      ]}
      mobileNavigation={[{ text: "Log In", href: "/users/sign_in" }]}
      partnerSlug={partnerSlug}
    >
      {() => (
        <FormContainer
          onSubmit={onSubmit}
          defaultValues={DEFAULT_VALUES}
          errors={getApiErrors(postSignupContext.error)}
        >
          <SignupForm />

          <LogoWrapper>
            <PartnerLogoLockup partnerSlug={partnerSlug} />
          </LogoWrapper>
        </FormContainer>
      )}
    </OnboardingFlow>
  );
};

export default View;
