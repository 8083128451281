import React, { useEffect } from "react";
import { Body, Heading } from "components/ui/Text";
import FormContainer, { FormSection } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import Button from "components/ui/Button";
import BankAccount from "components/shared/BankAccount";
import Loading from "components/ui/Loading";
import {
  getBankAccountsLinked,
  usePostCardApplicationConfirmRepaymentAccountMutation,
} from "store/api/apiSlice";
import * as Styled from "../styled";
import CancelApplicationButton from "../CancelApplicationButton";

interface Props {
  onSuccess: () => void;
}

const ConfirmRepaymentAccount: React.FC<Props> = ({ onSuccess }) => {
  const { data: linkedBankAccount, isLoading: isLoadingBankAccount } =
    getBankAccountsLinked();
  const [
    postCardApplicationConfirmRepaymentAccount,
    { isLoading: isConfirming, isSuccess: isConfirmed },
  ] = usePostCardApplicationConfirmRepaymentAccountMutation();

  const onSubmit = async () => {
    await postCardApplicationConfirmRepaymentAccount();
  };

  useEffect(() => {
    if (isConfirmed) {
      onSuccess();
    }
  }, [isConfirmed, onSuccess]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <Styled.Header>
        <Heading.H2 tag="h1">Set up autopay.</Heading.H2>
        <Body>
          Please verify that the information below is correct before submitting.
          This is the checking account Reset will use for repayment.
        </Body>
      </Styled.Header>

      <FormSection>
        {isLoadingBankAccount ? (
          <Loading />
        ) : (
          <BankAccount bankAccount={linkedBankAccount} />
        )}
      </FormSection>

      <MobileFixedFooter>
        <Button isLoading={isConfirming || isConfirmed} type="submit">
          Confirm
        </Button>
      </MobileFixedFooter>

      <Styled.CancelButtonWrapper>
        <CancelApplicationButton />
      </Styled.CancelButtonWrapper>
    </FormContainer>
  );
};

export default ConfirmRepaymentAccount;
