import React, { useEffect, useRef, useState } from "react";
import { Heading } from "components/ui/Text";
import Button, { ButtonLevel } from "components/ui/Button";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import { ModalApi } from "components/modal/Modal";
import ConfirmActionModal from "components/modal/ConfirmActionModal";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import IconList from "components/ui/IconList";
import { LinkButton } from "components/ui/Link";
import { OnboardingStatus } from "utils/api/onboarding";
import { usePostSkipPayrollMutation } from "store/api/apiSlice";
import * as Styled from "../styled";
import { LIST_ITEMS } from "./constants";

interface Props {
  onboardingStatus: OnboardingStatus;
  onSuccess: () => void;
  setIsPolling: (isPolling: boolean) => void;
  setIsManualEntry: (isManualEntry: boolean) => void;
}

const PayrollLinking: React.FC<Props> = ({
  onboardingStatus,
  onSuccess,
  setIsPolling,
  setIsManualEntry,
}) => {
  const pinwheelModal = useRef<PinwheelModalApi>(null);
  const skipModal = useRef<ModalApi>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [postSkipPayroll, postSkipPayrollContext] = usePostSkipPayrollMutation({
    fixedCacheKey: "skipPayroll",
  });

  const skip = () => {
    postSkipPayroll();
  };

  useEffect(() => {
    if (postSkipPayrollContext.isSuccess) {
      onSuccess();
    }
  }, [postSkipPayrollContext.isSuccess]);

  const openModal = () => {
    if (pinwheelModal && pinwheelModal.current) {
      pinwheelModal.current.open();
      setIsPolling(false);
    }
  };

  return (
    <Styled.FormStep>
      <Styled.Header>
        <Heading.H3 tag="h1">
          Next let's connect your payroll account.
        </Heading.H3>
      </Styled.Header>

      <IconList items={LIST_ITEMS} isLarge />

      <Styled.Footer>
        {onboardingStatus.skippable && (
          <Button
            level={ButtonLevel.ghost}
            onClick={() => skipModal.current.show()}
          >
            Skip for Now
          </Button>
        )}

        <Button
          isLoading={isLoading}
          onClick={openModal}
          data-test="btn-link-payroll"
          level={ButtonLevel.cta}
        >
          Connect My Payroll Account
        </Button>

        {!onboardingStatus.skippable && (
          <Styled.FooterLink>
            <LinkButton onClick={() => setIsManualEntry(true)} size="lg">
              I'm having trouble connecting
            </LinkButton>
          </Styled.FooterLink>
        )}
      </Styled.Footer>

      <Styled.FooterMobile>
        <MobileFixedFooter>
          <Styled.MobileFooterLink>
            {onboardingStatus.skippable ? (
              <LinkButton onClick={() => skipModal.current.show()} size="lg">
                Skip for Now
              </LinkButton>
            ) : (
              <LinkButton onClick={() => setIsManualEntry(true)} size="lg">
                Skip for Now
              </LinkButton>
            )}
          </Styled.MobileFooterLink>

          <Button
            isLoading={isLoading}
            onClick={openModal}
            data-test="btn-link-payroll"
            level={ButtonLevel.cta}
          >
            Connect
          </Button>
        </MobileFixedFooter>
      </Styled.FooterMobile>

      <Styled.ModalWrapper>
        <PinwheelModal
          ref={pinwheelModal}
          onSubmit={() => {
            setIsLoading(true);
          }}
          onExit={() => {
            setIsPolling(true);
          }}
          onSuccess={onSuccess}
        />
      </Styled.ModalWrapper>

      {onboardingStatus.skippable && (
        <ConfirmActionModal
          ref={skipModal}
          title={<>Are you sure you want to skip this&nbsp;step?</>}
          body="Linking your payroll account will allow you to access a larger
            portion of your income."
          action={skip}
          actionLabel="Yes, Skip for Now"
          cancelLabel="Go Back"
        />
      )}
    </Styled.FormStep>
  );
};

export default PayrollLinking;
