import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import UILabel from "components/ui/UILabel";
import { colors, mediaMin, transitions } from "utils/theme";
import { ICON_WRAPPER_WIDTH } from "../constants";

interface WrapperProps {
  $isDebit?: boolean;
}

export const IconWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: ${ICON_WRAPPER_WIDTH}px;
  height: ${ICON_WRAPPER_WIDTH}px;

  img {
    width: 24px;
  }
`;

export const LineItemLink = styled(RouterLink)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
`;

export const Main = styled.div`
  min-width: 1px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 2px 0;
`;

export const Left = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Right = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
`;

export const Title = styled(UILabel.Medium).attrs(() => ({ bold: true }))`
  margin: 0;
  overflow: hidden;

  display: flex;
  gap: 8px;

  white-space: nowrap;
  text-overflow: ellipsis;

  transition: all ${transitions.simple};

  & > * {
    flex: 0 1 auto;
  }
`;

export const TitleIcon = styled.div`
  transform: translateX(-8px);

  display: none;
  flex: 0 0 auto;

  color: ${colors.violet};
  opacity: 0;
  visibility: hidden;

  transition: all ${transitions.simple};

  svg {
    stroke-width: 18px;
    stroke: currentColor;
  }

  @media (hover: hover) {
    display: block;
  }
`;

export const Amount = styled(UILabel.Large).attrs((props) => ({
  color: props.$isDebit ? colors.violet : colors.azure,
}))<WrapperProps>`
  margin: 0;
  flex: 0 0 auto;

  ${mediaMin("md")} {
    font-size: 20px;
  }
`;

export const Wrapper = styled.div<{ $isInteractive?: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  min-width: 1px;
  gap: 12px;
  width: 100%;

  color: ${colors.midnight};

  text-decoration: none;

  ${(props) =>
    props.$isInteractive &&
    css`
      @media (hover: hover) {
        &:hover {
          ${Title} {
            color: ${colors.violet};
          }

          ${TitleIcon} {
            transform: translateX(0);

            opacity: 1;
            visibility: visible;
          }
        }
      }
    `}
`;
