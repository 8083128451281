import React, { useEffect, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import UILayout from "components/layout/UILayout";
import { Heading } from "components/ui/Text";
import Loading from "components/ui/Loading";
import { getUserSmsSettings } from "store/api/apiSlice";
import { Wrapper } from "../styled";
import * as Styled from "./styled";
import SmsOptInForm from "./OptInForm";
import SmsOptOutForm from "./OptOutForm";

interface OutletContext {
  drawer: {
    openWith: (component: React.ReactNode) => void;
    close: () => void;
  };
}

const TextMessageSettings: React.FC = () => {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const {
    data: settings,
    isLoading,
    refetch: refetchSettings,
  } = getUserSmsSettings({ pollingInterval: 1_000 });
  const navigate = useNavigate();
  const { drawer } = useOutletContext<OutletContext>();

  const onSuccess = () => {
    refetchSettings();

    timer.current = setTimeout(() => {
      navigate("/account");
    }, 1_000);
  };

  useEffect(
    () => () => {
      clearTimeout(timer.current);
    },
    []
  );

  if (isLoading) return <Loading />;

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">Text message settings</Heading.H3>
        <Styled.ContentWrapper>
          <Styled.Content>
            {settings?.optedIn ? (
              <SmsOptOutForm onSuccess={onSuccess} />
            ) : (
              <SmsOptInForm
                openDrawer={drawer.openWith}
                closeDrawer={drawer.close}
                onSuccess={onSuccess}
              />
            )}
          </Styled.Content>
        </Styled.ContentWrapper>
      </Wrapper>
    </UILayout>
  );
};

export default TextMessageSettings;
