import React from "react";
import { IconListItem } from "components/ui/IconList";

export const LIST_ITEMS: IconListItem[] = [
  {
    icon: "arrowProgress",
    title: "Why connect my payroll account?",
    body: (
      <>
        Reset uses payroll data to give you access to your income daily as you
        earn it. This has <strong>no impact</strong> on your direct deposit or
        the timing of your paycheck.
      </>
    ),
  },
  {
    icon: "stars",
    title: "Are there other benefits?",
    body: "Log in to Reset daily to keep track of your earnings and stay on top of your finances.",
  },
  {
    icon: "shield",
    title: "Is it safe?",
    body: "Your peace of mind is our priority. Reset uses Pinwheel to securely connect your payroll account and keep your data private.",
  },
];
