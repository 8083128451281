import styled, { css } from "styled-components";
import Tag from "components/ui/Tag";
import { colors, mediaMax } from "utils/theme";
import { RepaymentMethod } from "utils/api/user";

const getStatusTagStyles = ({ $status }: { $status: string }) => {
  switch ($status) {
    case RepaymentMethod.achDebitFlow:
      return css`
        background: ${colors.flax};
      `;
    case RepaymentMethod.directDepositFlow:
      return css`
        background: ${colors.heliotrope};
        color: white;
      `;
    default:
      return "";
  }
};

export const Wrapper = styled(Tag)<{ $status: string }>`
  width: fit-content;

  color: ${colors.midnight};
  background: ${colors.flax};

  ${getStatusTagStyles}

  ${mediaMax("lg")} {
    margin-top: 6px;
  }
`;
