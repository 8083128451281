import SiteNavigation from "components/ui/SiteNavigation";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;

  padding: 120px 0 64px;
`;

const NavigationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
`;

interface Props {
  children: React.ReactNode;
}

const SiteLayout: React.FC<Props> = ({ children }) => (
    <Wrapper>
      <NavigationWrapper>
        <SiteNavigation />
      </NavigationWrapper>

      {children}
    </Wrapper>
  );

export default SiteLayout;
