import React from "react";
import { useFormContext } from "react-hook-form";
import { CheckboxField, FormSection, SubmitButton } from "components/form";
import UILabel from "components/ui/UILabel";
import SidebarInfo from "components/ui/SidebarInfo";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import Loading from "components/ui/Loading";
import { getDisclosures, getUserProfile } from "store/api/apiSlice";
import { openWindow } from "utils/window";
import { formatPhoneNumber } from "utils/string";

interface Props {
  openDrawer: (body: React.ReactNode) => void;
  closeDrawer: () => void;
  isSuccess: boolean;
}

const SmsOptInFormFields: React.FC<Props> = ({
  openDrawer,
  closeDrawer,
  isSuccess,
}) => {
  const { data: userProfile, isLoading } = getUserProfile();
  const { data: disclosuresData, isLoading: isLoadingDisclosures } =
    getDisclosures(["messaging_terms_and_conditions"]);

  const { watch } = useFormContext();
  const acceptMessaging = watch("acceptMessaging");

  if (isLoading || isLoadingDisclosures) return <Loading />;

  const messagingTerms = (disclosuresData?.disclosures || [])[0];

  const onClickViewTerms = (e) => {
    e.preventDefault();
    openWindow(e.target.href);
  };

  const openSmsHelp = (e) => {
    e.preventDefault();
    openDrawer(
      <SidebarInfo closeDrawer={closeDrawer}>
        By checking this box, you agree to receive recurring automated text or
        SMS messages, including text or SMS messages sent using automatic
        telephone dialing systems by or on behalf of Reset at the mobile number
        you provided when signing up or registering, or at any other number you
        designate, about Reset&apos;s products and services, including
        personalized offers and promotions. You understand that these messages
        may be from Reset, our agents, representatives, service providers,
        affiliates, or anyone acting on our behalf and that you may receive
        these messages whether or not you complete the sign up or registration
        process. You understand that your consent to receive automated marketing
        text or SMS messages is not a condition of any purchase or service Reset
        offers. Message frequency varies. Message and data rates may apply.{" "}
        <a href={messagingTerms?.path} onClick={onClickViewTerms}>
          View Messaging Terms and Conditions
        </a>
      </SidebarInfo>
    );
  };

  return (
    <>
      <FormSection>
        <CheckboxField
          name="acceptMessaging"
          value="yes"
          label={
            <UILabel.Medium>
              I agree to receive recurring automated text or SMS messages about
              Reset's products and services, including personalized offers and
              promotions. Message frequency varies. Message and data rates may
              apply. Reply HELP for help and STOP to cancel.{" "}
              <a href="#" onClick={openSmsHelp}>
                What am I agreeing to?
              </a>
            </UILabel.Medium>
          }
        />
      </FormSection>

      <MobileFixedFooter>
        <SubmitButton disabled={!acceptMessaging} isSuccess={isSuccess}>
          Enable Text Notifications to{" "}
          {formatPhoneNumber(userProfile?.phoneNumber)}
        </SubmitButton>
      </MobileFixedFooter>
    </>
  );
};

export default SmsOptInFormFields;
