import React from "react";
import { capitalize } from "utils/string";
import { RepaymentMethod } from "utils/api/user";
import * as Styled from "./styled";

const mapOnboardingStatusToLabel = (status) => {
  switch (status) {
    case RepaymentMethod.achDebitFlow:
      return "ACH Debit";
    case RepaymentMethod.directDepositFlow:
      return "Direct Deposit";
    default:
      return capitalize(status.replace(/_/g, " "));
  }
};

interface Props {
  status: string;
}

const RepaymentMethodTag: React.FC<Props> = ({ status }) => (
  <Styled.Wrapper $status={status}>
    {mapOnboardingStatusToLabel(status)}
  </Styled.Wrapper>
);

export default RepaymentMethodTag;
