import { ApiUserRequest } from "utils/api/user";
import { getSessionPartner } from "utils/partners";
import { SignupFormData } from "./types";

export const getSignupBody = (values: SignupFormData) => {
  const partner = getSessionPartner();

  return {
    ...values,
    referralSlug: partner?.slug,
  } as ApiUserRequest;
};
