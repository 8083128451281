import React from "react";
import {
  useForm,
  FormProvider,
  UseFormReturn,
  FieldErrors,
} from "react-hook-form";
import styled from "styled-components";
import { mediaMax } from "utils/theme";
import { FormErrors } from "types/form";
import GeneralError from "./fields/GeneralError";

const getFieldErrors = (errors: FormErrors) => {
  const fieldErrors: FieldErrors = Object.entries(errors).reduce(
    (acc, [key, value]) => {
      const message = Array.isArray(value) ? value.join(".") : value;

      return { ...acc, [key]: { message, type: "custom" } };
    },
    {}
  );

  return fieldErrors;
};

const Form = styled.form`
  display: grid;
  width: 100%;
  gap: 36px;

  ${mediaMax("lg")} {
    gap: 24px;
  }
`;

interface Props {
  children: React.ReactNode;
  onSubmit: (
    values: { [key: string]: any },
    methods: UseFormReturn<
      {
        [key: string]: any;
      },
      any,
      undefined
    >
  ) => void;
  errors?: FormErrors;
  defaultValues?: { [key: string]: any };
}

const FormContainer: React.FC<Props> = ({
  children,
  onSubmit,
  defaultValues,
  errors,
}) => {
  const fieldErrors = errors ? getFieldErrors(errors) : undefined;

  const methods = useForm({
    defaultValues,
    errors: fieldErrors,
  });

  const submitHandler = (e) => onSubmit(e, methods);

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={(e) => {
          methods.clearErrors();
          methods.handleSubmit(submitHandler)(e);
        }}
      >
        <GeneralError />
        {children}
      </Form>
    </FormProvider>
  );
};

export default FormContainer;
