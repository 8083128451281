import { faker } from "@faker-js/faker";
import { factory, nullable, primaryKey, oneOf, manyOf } from "@mswjs/data";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { RepaymentMethod } from "utils/api/user";
import { getCurrentDate, getFalse, getRandomAmount, getTrue } from "./utils";

const db = factory({
  user: {
    id: primaryKey(uuid),
    createdAt: getCurrentDate,
    confirmed: getTrue,
    firstName: faker.person.firstName,
    lastName: faker.person.lastName,
    email: faker.internet.email,
    phoneNumber: () => faker.phone.number({ style: "national" }),
    currentAddress: oneOf("address"),
    currentCardAccount: oneOf("cardAccount"),
    accounts: {
      primaryBank: () => "Chase",
      payroll: () => "ADP",
    },
    enableProbationaryLimit: getFalse,
    eligibleForCard: Boolean,
    mfaEnabled: getFalse,
    impersonating: getFalse,
    admin: getFalse,
    partnerSlug: nullable(String),
    partnerLogo: nullable(oneOf("file")),
    repaymentMethod: () => RepaymentMethod.achDebitFlow,
    availableBankBalance: () => getRandomAmount(1_000, 1_500),
  },
  cardAccount: {
    id: primaryKey(uuid),
    issued: getCurrentDate,
    linked: getTrue,
    cards: manyOf("card"),
  },
  address: {
    id: primaryKey(uuid),
    streetOne: faker.location.streetAddress,
    streetTwo: nullable(faker.location.secondaryAddress),
    city: faker.location.city,
    stateAbbr: () => faker.location.state({ abbreviated: true }),
    zipCode: () => faker.location.zipCode({ format: "#####" }),
  },
  card: {
    id: primaryKey(uuid),
    lastFour: () => "2345",
    form: () => "VIRTUAL",
  },
  shift: {
    id: primaryKey(uuid),
    userId: nullable(String),
    earnings: manyOf("shiftEarning"),
    endDate: () => dayjs().format(),
  },
  shiftEarning: {
    id: primaryKey(uuid),
    hours: () => Math.ceil(40 + Math.random() * 40) / 10,
  },
  recurringExpense: {
    id: primaryKey(uuid),
    userId: nullable(String),
    lastOccurrenceDate: getCurrentDate,
    nextOccurrenceDate: getCurrentDate,
    merchantName: () => "",
    personalFinanceCategoryPrimary: () => "OTHER",
    personalFinanceCategorySecondary: () => "OTHER_OTHER",
    description: faker.company.name,
    averageAmount: () => getRandomAmount(20, 150),
  },
  cardTransaction: {
    id: primaryKey(uuid),
    userId: nullable(String),
    createdAt: () => dayjs().format(),
    amount: () => getRandomAmount(10, 100),
    merchantName: faker.company.name,
    networkState: () => "",
    customerState: () => "posted",
    syncteraTransactionId: uuid,
    syncteraAccountId: uuid,
  },
  paystub: {
    id: primaryKey(uuid),
    userId: nullable(String),
    payDate: () => dayjs().format(),
    grossPayAmount: () => getRandomAmount(2_400, 2_900),
    netPayAmount: () => getRandomAmount(1_800, 2_200),
  },
  file: {
    id: primaryKey(uuid),
    data: String,
    type: String,
  },
});

export default db;
