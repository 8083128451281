import React from "react";
import { Body, Heading } from "components/ui/Text";
import DisappearingTile from "components/ui/DisappearingTile";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import Button from "components/ui/Button";
import * as Styled from "../styled";

interface Props {
  isEligible: boolean;
}

const WaitlistMessage: React.FC<Props> = ({ isEligible }) => {
  const onClickConnect = () => {
    window.location.reload();
  };

  return (
    <Styled.FormStep>
      <Styled.Header>
        <Heading.H3 tag="h1">Thank you for your interest in Reset.</Heading.H3>
      </Styled.Header>

      <DisappearingTile size="xl">
        <Body>
          {isEligible ? (
            <>
              Our records indicate that your payroll provider may be supported.
              You can click below to try connecting to your payroll account, or{" "}
              <a href="mailto:hello@getreset.co?subject=Help linking payroll">
                contact us
              </a>{" "}
              for assistance.
            </>
          ) : (
            <>
              Upon reviewing your linked accounts, they do not currently meet
              our eligibility criteria. Unfortunately, we're unable to move your
              application forward at this&nbsp;time.
            </>
          )}
        </Body>
      </DisappearingTile>

      {isEligible && (
        <MobileFixedFooter>
          <Button onClick={onClickConnect}>Connect Payroll Account</Button>
        </MobileFixedFooter>
      )}
    </Styled.FormStep>
  );
};

export default WaitlistMessage;
