import React from "react";
import FormContainer from "components/form";
import {
  getDashboardOverview,
  getUserProfile,
  usePostQualifierMutation,
} from "store/api/apiSlice";
import { Eligibility } from "types/signup";
import Form from "./Form";
import { getIsEligible, getWaitlistBody } from "./utils";

interface Props {
  setIsEligible: (isEligible: boolean) => void;
}

const ManualEntry: React.FC<Props> = ({ setIsEligible }) => {
  const { data: userProfile } = getUserProfile();
  const { data: dashboardOverview } = getDashboardOverview();
  const [postWaitlist] = usePostQualifierMutation({
    fixedCacheKey: "waitlist",
  });

  const onSubmit = async ({ eligibility, ...values }) => {
    const isIncomplete = Object.values(eligibility).some(
      (v) => v === Eligibility.indeterminate
    );

    if (isIncomplete) return;

    const isEligible = getIsEligible(eligibility);
    setIsEligible(isEligible);

    const body = getWaitlistBody({
      userProfile,
      dashboardOverview,
      values,
    });

    await postWaitlist(body);
  };

  return (
    <FormContainer onSubmit={onSubmit}>
      <Form />
    </FormContainer>
  );
};

export default ManualEntry;
