import React from "react";
import { ApiAdminUserMini } from "utils/api/admin/user";
import { ApiUserProfile } from "utils/api/user";
import { RouterLink } from "components/ui/Link";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";
import { formatDateString } from "utils/date";
import { formatCurrency } from "utils/string";
import { Pre } from "components/ui/Pre";
import OnboardingStatusTag from "./OnboardingStatusTag";
import RepaymentMethodTag from "./RepaymentMethodTag";
import PartnerTag from "./PartnerTag";

export const getColumnValue = ({
  user,
  isOtherAdmin,
  name,
}: {
  user: ApiAdminUserMini;
  isOtherAdmin?: boolean;
  name: string;
}) => {
  switch (name) {
    case "id":
      return isOtherAdmin ? (
        user.id.slice(-4)
      ) : (
        <RouterLink to={`/admin/users/${user.id}`} color="azure">
          {user.id.slice(-4)}
        </RouterLink>
      );
    case "name":
      return user.name;
    case "email":
      return user.email;
    case "admin":
      return (
        user.admin && (
          <Icon
            name="statusSuccess"
            color={colors.midnight}
            colorSecondary={colors.leaf}
          />
        )
      );
    case "onboardingStatus":
      return <OnboardingStatusTag status={user.onboardingStatus} />;
    case "deactivated":
      return (
        user.deactivated && (
          <Icon
            name="statusDanger"
            color="white"
            colorSecondary={colors.cerise}
            size="sm"
          />
        )
      );
    case "lastSignInAt":
      return user.lastSignInAt
        ? formatDateString(user.lastSignInAt, "numericShortWithTime")
        : "";
    case "createdAt":
      return formatDateString(user.createdAt, "numericShortWithTime");
    case "dsl":
      return formatCurrency(user.dsl || 0, true);
    case "latestTransaction":
      return user.latestTransactionDate
        ? formatDateString(user.latestTransactionDate, "numericShortWithTime")
        : "";
    case "payDate":
      return user.payDate ? formatDateString(user.payDate) : "";
    case "payDates":
      return user.payDates ? <Pre json={user.payDates} /> : "";
    case "repaymentMethod":
      return <RepaymentMethodTag status={user.userConfig.repaymentMethod} />;
    case "partner":
      return <PartnerTag slug={user.userConfig.partnerSlug} />;
    case "referral":
      return <PartnerTag slug={user.userConfig.referralSlug} />;
    default:
      return "";
  }
};

export const isOtherAdmin = (
  user: ApiAdminUserMini,
  userProfile: ApiUserProfile
) => user.admin && userProfile?.id !== user.id;
