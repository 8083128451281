import styled from "styled-components";
import { colors, ease } from "utils/theme";
import Tag from "components/ui/Tag";
import UILabel from "components/ui/UILabel";

export const getColor = (strengthPercentage: number) => {
  if (strengthPercentage < 75)
    return `color-mix(in srgb, ${colors.azure} ${strengthPercentage + 25}%, ${colors.flax})`;

  return `color-mix(in srgb, ${colors.violet} ${(strengthPercentage - 75) * 4}%, ${colors.azure})`;
};

export const Wrapper = styled.div`
  display: grid;
  gap: 4px;
`;

export const Meter = styled.div<{
  $strengthPercentage: number;
}>`
  position: relative;

  height: 8px;
  width: 100%;
  overflow: hidden;

  border-radius: 9999px;
  background: color-mix(in srgb, ${colors.azure} 10%, white);

  ${(props) => `
    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      transform: scaleX(${props.$strengthPercentage}%);
      opacity: ${props.$strengthPercentage / 100};
      transform-origin: left center;

      width: 100%;
      height: 100%;

      background: ${getColor(props.$strengthPercentage)};

      transition: transform 0.3s ${ease.outQuart};
    }
  `};
`;

export const LabelWrapper = styled.div`
  position: relative;
`;

export const StrengthTag = styled(Tag)`
  position: absolute;
  right: 0;
  bottom: 4px;
`;

export const Message = styled(UILabel.Medium)`
  padding-top: 8px;

  opacity: 0.8;
`;
