import React, { useEffect } from "react";
import FormContainer from "components/form";
import { usePostUserSmsOptInMutation } from "store/api/apiSlice";
import SmsOptInFormFields from "./Fields";

interface Props {
  openDrawer: (body: React.ReactNode) => void;
  closeDrawer: () => void;
  onSuccess: () => void;
}

const SmsOptInForm: React.FC<Props> = ({
  openDrawer,
  closeDrawer,
  onSuccess,
}) => {
  const [postUserSmsOptIn, postUserSmsOptInContext] =
    usePostUserSmsOptInMutation();

  const onSubmit = async (values) => {
    if (values.acceptMessaging === "yes") {
      await postUserSmsOptIn();
    }
  };

  useEffect(() => {
    if (postUserSmsOptInContext.isSuccess) {
      onSuccess();
    }
  }, [postUserSmsOptInContext.isSuccess]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <SmsOptInFormFields
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        isSuccess={postUserSmsOptInContext.isSuccess}
      />
    </FormContainer>
  );
};

export default SmsOptInForm;
