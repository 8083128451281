import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
`;

export const Content = styled.div`
  --padding-x: 16px;

  margin: 0 auto;
  max-width: 576px;
  width: 100%;
  flex: 1 1 auto;
`;
