import React from "react";
import { FormMessage, SubmitButton } from "components/form";
import { Heading } from "components/ui/Text";
import EligibilityFooter from "components/shared/EligibilityFooter";
import environment from "utils/environment";
import { Eligibility } from "types/signup";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { ButtonLevel } from "components/ui/Button";
import PayrollQuestionnaire from "./PayrollQuestionnaire";
import * as Styled from "./styled";

interface Props {
  isSubmitting: boolean;
  hasError: { payroll: boolean };
  updateEligibility: (key: "payroll") => (value: Eligibility) => void;
  updateHasError: (key: "payroll") => (value: boolean) => void;
  eligibility: { payroll: Eligibility };
}

const Fields: React.FC<Props> = ({
  hasError,
  updateEligibility,
  updateHasError,
  eligibility,
}) => (
  <Styled.Wrapper>
    <Styled.FormWrapper>
      <Styled.FormSection>
        <Styled.SectionHeading>
          <Heading.H3 tag="h1">
            Tell us about your primary source of income.
          </Heading.H3>
        </Styled.SectionHeading>

        {hasError.payroll && (
          <FormMessage>Please fill out all fields</FormMessage>
        )}

        <PayrollQuestionnaire
          setEligibility={updateEligibility("payroll")}
          setHasError={updateHasError("payroll")}
        />

        {environment.signupFormDebugMode &&
          process.env.NODE_ENV === "development" && (
            <div>Payroll: {eligibility.payroll}</div>
          )}
      </Styled.FormSection>
    </Styled.FormWrapper>

    <MobileFixedFooter>
      <SubmitButton level={ButtonLevel.cta}>Continue</SubmitButton>
    </MobileFixedFooter>

    <EligibilityFooter />
  </Styled.Wrapper>
);

export default Fields;
