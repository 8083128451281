import { MenuItemType, MenuSection } from "components/ui/Menu/types";

export const MENU: MenuSection[] = [
  {
    title: "Links",
    items: [
      {
        type: MenuItemType.link,
        children: "Users",
        icon: "userCircle",
        data: {
          href: "/admin/users",
        },
      },
      {
        type: MenuItemType.link,
        children: "Repayment Schedule",
        icon: "calendar",
        data: {
          href: "/admin/repayment-schedule",
        },
      },
      {
        type: MenuItemType.link,
        children: "Card Transactions",
        icon: "creditCard",
        data: {
          href: "/admin/card-transactions",
        },
      },
      {
        type: MenuItemType.link,
        children: "Waitlist",
        icon: "squareList",
        data: {
          href: "/admin/waitlists",
        },
      },
      {
        type: MenuItemType.link,
        children: "Financial Health Survey",
        icon: "stethoscope",
        data: {
          href: "/admin/financial-health",
        },
      },
    ],
  },
];
