import ReactOnRails from "react-on-rails";

import App from "../bundles/App";
import Signup from "../bundles/Signup";
import EditPassword from "../bundles/Passwords/Edit";
import Onboarding from "../bundles/Onboarding";
import CardApplication from "../bundles/CardApplication";
import Home from "../bundles/Site/Home";
import GeneralPage from "../bundles/Site/GeneralPage";
import CmsPage from "../bundles/Site/CmsPage";
import CmsPost from "../bundles/Site/CmsPost";
import FinancialHealth from "../bundles/Site/FinancialHealth";
import BudgetCalculator from "../bundles/Site/BudgetCalculator";
import Navigation from "../bundles/Site/Navigation";
import Admin from "../bundles/Admin";
import NotFound from "../bundles/NotFound";
import Styles from "../bundles/Styles";
import Scripts from "../bundles/Scripts";

ReactOnRails.register({
  // App
  App,

  // Signup
  Signup,
  EditPassword,
  Onboarding,
  CardApplication,

  // Admin
  Admin,

  // Components

  // Static
  Home,
  GeneralPage,
  CmsPage,
  CmsPost,
  FinancialHealth,
  BudgetCalculator,
  Navigation,
  NotFound,

  // Globals
  Styles,
  Scripts,
});
