import React from "react";
import Input, { InputProps } from "../ui/Input";
import FieldContainer from "../FieldContainer";

interface Props extends InputProps {
  label?: React.ReactNode;
  errorName?: string;
}

const InputField: React.FC<Props> = ({ name, label, errorName, ...props }) => (
  <FieldContainer name={name} label={label} errorName={errorName || name}>
    {({ inputProps, error }) => (
      <Input name={name} hasError={!!error} {...inputProps} {...props} />
    )}
  </FieldContainer>
);

export default InputField;
