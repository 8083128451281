import React from "react";
import { TagLevel } from "components/ui/Tag";
import Label from "../Label";
import * as Styled from "./styled";

const mapScoreToTagLevel = (score: number) => {
  switch (score) {
    case 0:
    case 1:
    case 2:
      return TagLevel.warning;
    case 3:
      return TagLevel.pending;
    case 4:
      return TagLevel.info;
    default:
      return TagLevel.pending;
  }
};

interface Props {
  score: number;
  message: string[];
  label: string;
  password: string;
}

const PasswordStrength: React.FC<Props> = ({
  score,
  message,
  label,
  password,
}) => {
  const strengthPercentage = Math.min(
    ((score + Math.min(password.length, 12) / 24) / 4) * 100,
    100
  ); // Allow for some movement as password length increases

  return (
    <Styled.Wrapper>
      <Styled.LabelWrapper>
        <Label>Strength</Label>

        {score > 0 && (
          <Styled.StrengthTag level={mapScoreToTagLevel(score)}>
            {label}
          </Styled.StrengthTag>
        )}
      </Styled.LabelWrapper>

      <Styled.Meter $strengthPercentage={strengthPercentage} />

      <Styled.Message>{message.length > 0 && message.join(" ")}</Styled.Message>
    </Styled.Wrapper>
  );
};

export default PasswordStrength;
