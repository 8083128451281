import React, { forwardRef, useImperativeHandle, useState } from "react";
import BasePinwheelModal, { LinkResult } from "@pinwheel/react-modal";
import { getToken, storeIdentifiers } from "utils/api/pinwheel";
import {
  getUserProfile,
  usePostLogPinwheelEventMutation,
} from "store/api/apiSlice";

interface Props {
  onSuccess?: (result: LinkResult, callbackData?: any) => void;
  onSubmit?: (result: LinkResult, callbackData?: any) => void;
  onExit?: () => void;
  isDds?: boolean;
  closeOnSuccess?: boolean;
}

export interface ModalApi {
  open: (callbackData?: any) => void;
}

const PinwheelModal = (
  { onSuccess, onSubmit, onExit, isDds, closeOnSuccess }: Props,
  ref: React.ForwardedRef<unknown>
) => {
  const [postLogPinwheelEvent] = usePostLogPinwheelEventMutation();
  const { data: userProfile } = getUserProfile();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [callbackData, setCallbackData] = useState<any | undefined>();

  const open = async (data) => {
    setCallbackData(data);

    const res = await getToken(isDds);
    if (res.success && res.link_token) {
      setLinkToken(res.link_token);
    }
  };

  const cleanup = () => {
    setCallbackData(undefined);
  };

  const onLinkSuccess = async (result) => {
    if (closeOnSuccess) {
      setLinkToken(null);
    }

    if (onSubmit) {
      onSubmit(result, callbackData);
    }

    await storeIdentifiers(result.accountId, result.platformId);

    if (onSuccess) {
      onSuccess(result, callbackData);
    }

    cleanup();
  };

  const onModalExit = () => {
    setLinkToken(null);

    if (onExit) {
      onExit();
    }

    cleanup();
  };

  const onEvent = (eventName, payload: { [key: string]: any }) => {
    postLogPinwheelEvent({
      eventName,
      eventData: JSON.stringify(payload),
      userId: userProfile?.id,
    });
  };

  useImperativeHandle(ref, () => ({ open }));

  return (
    <BasePinwheelModal
      linkToken={linkToken}
      open={!!linkToken}
      onExit={onModalExit}
      onEvent={onEvent}
      onSuccess={onLinkSuccess}
    />
  );
};

export default forwardRef(PinwheelModal);
