import React, { useRef } from "react";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import {
  ApiNotification,
  ApiNotificationErrorType,
  ApiNotificationSource,
  ApiNotificationType,
} from "utils/api/notifications";
import { getDashboardOverview } from "store/api/apiSlice";
import { getNotificationsWithUi } from "utils/notifications";
import ListItem from "./ListItem";
import * as Styled from "./styled";

interface Props {
  notifications?: ApiNotification[];
  onSuccess?: () => void;
}

const NotificationsList: React.FC<Props> = ({ notifications, onSuccess }) => {
  const { data: dashboardOverview } = getDashboardOverview();
  const pinwheelModalRef = useRef<PinwheelModalApi>(null);
  const plaidModalRef = useRef<PlaidModalApi>(null);

  const pinwheelRelinkHandler = (id) => {
    if (pinwheelModalRef && pinwheelModalRef.current) {
      pinwheelModalRef.current.open(id);
    }
  };

  const plaidRelinkHandler = (id) => {
    if (plaidModalRef && plaidModalRef.current) {
      plaidModalRef.current.open(id);
    }
  };

  const mapNotificationToHandler = async (notification: ApiNotification) => {
    if (
      notification.params?.errorType ===
      ApiNotificationErrorType.monitoring_status_unavailable
    ) {
      switch (notification.params?.source) {
        case ApiNotificationSource.pinwheel:
          pinwheelRelinkHandler(notification.id);
          break;
        case ApiNotificationSource.plaid:
          plaidRelinkHandler(notification.id);
          break;
        default:
          break;
      }
    } else {
      switch (notification.type) {
        case ApiNotificationType.optedToSkipPayrollLinking:
          pinwheelRelinkHandler(notification.id);
          break;
        default:
          break;
      }
    }
  };

  const onRelinkSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const notificationsWithUi = getNotificationsWithUi(notifications || []);

  if (notificationsWithUi.length === 0) return null;

  return (
    <>
      <Styled.List>
        {notificationsWithUi.map((notification) => (
          <li key={notification.id}>
            <ListItem
              notification={notification}
              accounts={dashboardOverview?.accounts}
              onClick={() => {
                mapNotificationToHandler(notification);
              }}
            />
          </li>
        ))}
      </Styled.List>

      <PinwheelModal ref={pinwheelModalRef} onSuccess={onRelinkSuccess} />

      <PlaidModal ref={plaidModalRef} onSuccess={onRelinkSuccess} />
    </>
  );
};

export default NotificationsList;
