import React from "react";
import styled from "styled-components";

const StyledPre = styled.pre`
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

export const Pre = ({ json }) => (
  <StyledPre>{JSON.stringify(json, undefined, 2)}</StyledPre>
);
