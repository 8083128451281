import { VerificationStatus } from "types/verification";
import { SafeToSpendComponents } from "../dashboard";

export interface ApiAdminUserPayDates {
  paveDerived?: string;
  pinwheelDerived?: string;
  resetPaystubDerived?: string;
  payrollAccountPayPeriodStartDateDerived?: string;
}

export interface ApiAdminUserMini {
  id: string;
  createdAt: string;
  lastSignInAt: string;
  onboardingStatus: string;
  deactivated: boolean;
  name: string;
  email: string;
  admin: boolean;
  dsl?: number;
  latestTransactionDate?: string;
  payDate?: string;
  payDates?: ApiAdminUserPayDates;
  userConfig: ApiAdminUserConfig;
}

export interface ApiAdminUsers {
  users: ApiAdminUserMini[];
  totalPages: number;
}

export interface ApiAdminUsersRequest {
  page: number;
  sortBy?: string;
}

export interface ApiAdminUserExpense {
  id: string;
  averageAmountInt: number;
  merchantName?: string;
  description: string;
  lastOccurrenceDate: string;
}

export interface ApiAdminUserPaystub {
  payDate: string;
  payPeriodStartDate: string;
  payPeriodEndDate: string;
  employerName: string;
  grossPayAmountInt: number;
  netPayAmountInt: number;
  checkAmountInt: number;
}

interface Card {
  id: string;
}

export enum ApiPayFrequency {
  weekly = "weekly",
  biweekly = "bi_weekly",
  semimonthly = "semi_monthly",
  monthly = "monthly",
}

export interface ApiAdminUserConfig {
  partnerSlug?: string;
  referralSlug?: string;
  enableProbationaryLimit: boolean;
  enableFutureDatedDirectDepositLimit: boolean;
  alwaysIssuePhysicalCard: boolean;
  requireInternalReviewAfterIdVerification: boolean;
  repaymentMethod: string;
  earnedWageAccessMultiplier: number;
  requirePayrollLinking: boolean;
}

export interface ApiAdminUser extends ApiAdminUserMini {
  linkedBankAccountName: string;
  payrollAccount: {
    institutionName: string;
    compensationUnit: string;
    compensationAmountInt: number;
    payFrequency: ApiPayFrequency;
    payPeriodStartDate: string | null;
    overriddenAt: string | null;
  };
  userConfig: ApiAdminUserConfig;
  annualizedCompensation: number;
  safeToSpendComponents: SafeToSpendComponents;
  recurringExpenses: ApiAdminUserExpense[];
  lastPaystub?: ApiAdminUserPaystub;
  identityVerificationStatus: VerificationStatus;
  cards?: {
    physical?: Card;
    virtual?: Card;
  };
  eligible: boolean | null;
  eligibilityCriteria: { [key: string]: boolean } | null;
  hubspotContactUrl: string;
  syncteraCustomerUrl?: string;
  payrollLinkingStatus: string;
}

export interface ApiAdminUserPinwheelStatus {
  connected: boolean;
  monitoringStatus: string;
  pinwheelAccountId: string;
  linkToken: string;
  dataRefreshedAt: {
    directDepositAllocations: string;
    income: string;
    employment: string;
    identity: string;
    shifts: string;
    paystubs: string;
  };
}

export interface ApiAdminUserDirectDepositAllocation {
  accountName: string;
  allocationType: string;
  amount: number;
  percentage: number;
  priority: number;
  routingNumber: number;
  maskedAccountNumber: number;
}

export interface ApiAdminUserDirectDeposit {
  allocations: ApiAdminUserDirectDepositAllocation[];
}

export interface ApiAdminUserPlaidTransaction {
  id: string;
  date: string;
  amount: number;
  category: string;
  categoryId: string;
  merchantName: string;
  name: string;
  transactionType: string;
}

export interface ApiAdminUserPlaidStatus {
  balancesUpdatedAt: string;
  error: string;
  transactionsFailUpdatedAt: string;
  transactionsSuccessfulUpdatedAt: string;
  plaidItemId: string;
}

export interface ApiAdminUserPlaidTransactions {
  plaidTransactions: ApiAdminUserPlaidTransaction[];
}

export interface ApiAdminUserAnnualizedIncome {
  sortedNetPayAmounts: number[];
  sortedNetPayRatios: number[];
  annualizedPayWithMin: number;
  annualizedPayWithMax: number;
  annualizedPayWithAvg: number;
}

export interface ApiAdminUserPaveStatus {
  characteristics: {};
  incomes: {};
  scores: {};
}
