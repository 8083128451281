import React, { useEffect } from "react";
import FormContainer, { SubmitButton } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { usePostUserSmsOptOutMutation } from "store/api/apiSlice";

interface Props {
  onSuccess: () => void;
}

const SmsOptOutForm: React.FC<Props> = ({ onSuccess }) => {
  const [postUserSmsOptOut, postUserSmsOptOutContext] =
    usePostUserSmsOptOutMutation();

  const onSubmit = async () => {
    await postUserSmsOptOut();
  };

  useEffect(() => {
    if (postUserSmsOptOutContext.isSuccess) {
      onSuccess();
    }
  }, [postUserSmsOptOutContext.isSuccess]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <MobileFixedFooter>
        <SubmitButton isSuccess={postUserSmsOptOutContext.isSuccess}>
          Disable Text Notifications
        </SubmitButton>
      </MobileFixedFooter>
    </FormContainer>
  );
};

export default SmsOptOutForm;
