import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styled from "styled-components";
import FormContainer, {
  MaskedField,
  MaskType,
  SubmitButton,
} from "components/form";
import { usePostAdminUserOverridePayPeriodMutation } from "store/admin/api/apiSlice";
import { ApiPayFrequency } from "utils/api/admin/user";
import UILabel from "components/ui/UILabel";
import { Heading } from "components/ui/Text";
import Modal, { ModalApi } from "./Modal";

export const PAY_FREQUENCY_OPTIONS = [
  { name: "Weekly", value: ApiPayFrequency.weekly },
  { name: "Biweekly", value: ApiPayFrequency.biweekly },
  { name: "Semimonthly", value: ApiPayFrequency.semimonthly },
  { name: "Monthly", value: ApiPayFrequency.monthly },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface Props {
  id: string;
  onSuccess: () => void;
}

const OverridePayPeriodModal = (
  { id, onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postOverridePayPeriod, postOverridePayPeriodContext] =
    usePostAdminUserOverridePayPeriodMutation();

  const onSubmit = (values: { payPeriodStartDate: string }) => {
    const overrideParams: {
      id: string;
      pay_period_start_date: string;
    } = {
      id,
      pay_period_start_date: values.payPeriodStartDate,
    };

    overrideParams.pay_period_start_date = new Date(values.payPeriodStartDate)
      .toISOString()
      .replace(/T.+/, "");

    postOverridePayPeriod(overrideParams);
  };

  useEffect(() => {
    if (postOverridePayPeriodContext.isSuccess && onSuccess) {
      onSuccess();
    }

    ref.current?.dismiss();
  }, [postOverridePayPeriodContext.isSuccess]);

  return (
    <Modal ref={ref}>
      <Wrapper>
        <Heading.H4 tag="h2">Override Pay Period</Heading.H4>

        <FormContainer onSubmit={onSubmit}>
          <UILabel.Large color="cerise">
            We do not project the pay period start date! We will use what is
            provided forever.
          </UILabel.Large>
          <UILabel.Large color="cerise">
            Once we receive the first deposit, we will ignore this value and
            start using the Reset paystub date instead.
          </UILabel.Large>

          <MaskedField
            maskType={MaskType.date}
            placeholder="mm/dd/yyyy"
            name="payPeriodStartDate"
            label="Pay Period Start Date"
            required
          />

          <SubmitButton>Override Pay Period</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(OverridePayPeriodModal);
